
function addQuestionIdAndAnswer(questionId, answers){
    let finalValue
    const answer = answers.filter(a => a.questionId === questionId)
    // sometimes there are duplicate answers in the answers array so filter out one with a value
    if(answer.length > 1) {
        const tempAnswer = answer.find(a => a.value)
        if(tempAnswer) {
            finalValue = tempAnswer.value
        } else {
            finalValue = answer[0].value
        }
    // if only returns one answer, then get value from answers array
    } else if(answer.length === 1) {
        finalValue = answer[0].value
    // if there is no answer found then final value will be initialized to null
    } else {
        finalValue = null
    }

    let obj = {
        questionId: questionId,
        value: finalValue
    }
    
    return obj
}

function recursiveCreateAnswersArray(answers, questionsArray) {
    return new Promise (async (res, rej) => {
        let answersArray = []
        for(const i in questionsArray) {
            const question = questionsArray[i];
            let hasOptions;
            
            if (!question.hasOwnProperty('question')) {
                if(question) {
                    hasOptions = question.answerType === 'radio' || question.answerType === 'select' || question.answerType === 'nationality';
                    if(question.answerType === 'address') {
                        await recursiveCreateAnswersArray(answers, question.addressQuestions).then(answers => {
                            answersArray.push([].concat(...answers))
                        })
                    } else if (question.answerType === 'groupedQuestions' || question.answerType === 'checkboxGroup') {
                        await recursiveCreateAnswersArray(answers, question.groupQuestions).then(answers => {
                            answersArray.push([].concat(...answers))
                        })
                    } else {
                        answersArray.push(addQuestionIdAndAnswer(question.id, answers))
                    }
                } 
            } else {
                if(question.question) {
                    if(question.question.answerType === 'address') {
                        await recursiveCreateAnswersArray(answers, question.question.addressQuestions).then(answers => {
                            answersArray.push([].concat(...answers))
                        })
                    } else if (question.question.answerType === 'groupedQuestions' || question.question.answerType === 'checkboxGroup') {
                        await recursiveCreateAnswersArray(answers, question.question.groupQuestions).then(answers => {
                            answersArray.push([].concat(...answers))
                        })
                    } else {
                        hasOptions = question.question.answerType === 'radio' || question.question.answerType === 'select' || question.question.answerType === 'nationality';
                        answersArray.push(addQuestionIdAndAnswer(question.question.id, answers))
                    }
                } 
            }

            if (hasOptions) {
                let options;

                if (!question.hasOwnProperty('question')) {
                    options = question.options
                } else {
                    options = question.question.options
                }

                for (const j in options) {
                    const option = options[j];
                    const noFollowUps = (option.followUps === null || option.followUps === undefined) || option.followUps.questions === null || option.followUps.questions === [] || option.followUps.questions[0] === null ||
                    option.followUps.questions.length === 0

                    if (noFollowUps) {
                    } else {
                        await recursiveCreateAnswersArray(answers, option.followUps.questions).then(answers => {
                            answersArray.push([].concat(...answers))
                        })
                    }
                }
            }
        }

        res([].concat(...answersArray))
    })
}

export default recursiveCreateAnswersArray