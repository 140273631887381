import React from 'react';
import Loader from '../../layout/loader.jsx'
import API from '../../../services/api.jsx'
import User from '../../../services/user.jsx'
import Head from '../../layout/head.jsx'
import Content from '../../layout/content.jsx'
import ApplicationPlansForm from './form.jsx';
import { toast } from 'react-toastify'
// import ApplicationPersonalForm from './personal/form.jsx';

class ApplicationNew extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			filters: {
				user: User.current().id
			},
			breadcrumb: { title: 'Back to Applications', path: '/applications' },
			tabs: {
				param: props.match.params.applicationId,
				links: [
					{ title: 'Plan', path: '/applications/new/section/plans' },
					{ title: 'Personal', path: `/applications/${props.match.params.applicationId}/section/personal` },
					{ title: 'Dependents', path: `/applications/${props.match.params.applicationId}/section/dependents` },
					{ title: 'Medical', path: `/applications/${props.match.params.applicationId}/section/medical` },
					{ title: 'Doctor', path: `/applications/${props.match.params.applicationId}/section/doctor` },
					{ title: 'Emergency Contact', path: `/applications/${props.match.params.applicationId}/section/emergency` },
					{ title: 'Other Plans', path: `/applications/${props.match.params.applicationId}/section/other-plans` }
				]
			},
			application: {
				name: 'Application'
			},
			providers: null,
			selected: {
				provider: null,
				plan: null
			}
		}
	}

	componentDidMount() {
		this.setState({loading: true})
		API.listProviderPlans().then(providersPlans => {
			for(let i in providersPlans) {
				let provider = providersPlans[i]

				provider.selected = false
			}
			let selected = {}

			selected.provider = providersPlans[0]
			selected.plan = {...providersPlans[0].plans[0]}

			this.setState({loading: false, selected, providers: providersPlans })
		}).catch(err => {
			console.log(err)
			toast.error("There was a problem loading the plans");
		})
	}

	save = (e) => {
		this.setState({loading: true})
		const planObj = {
			plan: e.plan.id,
			operator: this.state.filters.user
		}

		API.create('applications', planObj).then(result => {
				this.setState({loading: false})
				this.props.history.push(`/applications/${result.id}/section/personal`)
				toast.success("The application has been created");
			})
			.catch(err => {
				console.log(err)
				toast.error("There was a problem creating this application");
			})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={this.state.application.name} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs}/>
				<Content>
					<ApplicationPlansForm 
						fields={this.state.fields} 
						entity={this.state.providers}
						save={this.save}
						cancel={() => this.props.history.push('/applications')}
						selected={this.state.selected}
					/>
				</Content>
			</>
	}
}

export default ApplicationNew;
