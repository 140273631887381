import React from 'react';
import FileHandler from '../layout/file-handler/fileHandler.jsx'
import Loader from '../layout/loader.jsx'

class Form extends React.Component {
	constructor(props) {
		super(props)
		this.state = props.entity
	}

	updateField(e, key) {
		this.setState({...this.state, [key]: e.target.value })
	}

    updateAnswerType(e, key) {
        let value = e.target.value

        if(key === 'hasMedicalFollowUps' || key === 'uppercase' || key === 'isInsuredOneDependantOne' || key === 'medicalQuestionsForMultipleInsured') 
            value = e.target.value === 'true'

        this.setState({...this.state, answerFormat: {...this.state.answerFormat, [key]: value}})
    }

    showPdf = (file) => {
        this.setState({
            ...this.state,
            tempFile: file
        })
    }


	render() {
        if(!this.props.entity)
            return <Loader />
        else
		return (
			<form onSubmit={(e) => { e.preventDefault(); this.props.save({...this.state})}}>
				<div className="actions clearfix">
					<div className="buttons clearfix">
						<div className="button grey" onClick={(e) => {e.preventDefault(); this.props.cancel()}}>Cancel</div>
						<button className="blue" onClick={(e) => { e.preventDefault(); this.props.save({...this.state})}}>Save</button>
					</div>
				</div>

				<div className="block">
					<div className="fields clearfix">
                        <div className="field">
                            <label>Name</label>
                            <input type="text" onChange={(e) => this.updateField(e, 'name')} required value={this.state.name} />
                        </div>
                        <div className="file-handler-container non-image">
                            <div className="field file-name">
                                <label>PDF file name</label> 
                                <p>{this.state.pdfTemplate ? this.state.pdfTemplate.name : this.state.tempFile && this.state.tempFile.name}</p>
                            </div>
                            <div className="field">
                                <FileHandler 
                                    showFile={this.showPdf}
                                    fileType={'pdf'}
                                    type={'pdfTemplate'}
                                    dropzone
                                />
                            </div>
                        </div>
					</div>

                    <div className="fields clearfix wrap">
                        <div className="field">
                            <label>Uppercase answers</label>
                            <div className="radio">
                                <input id="uppercase-true" name="uppercase" type="radio" value={true} onChange={(e) => this.updateAnswerType(e, e.target.name)} checked={this.state.answerFormat.uppercase === "true" || this.state.answerFormat.uppercase === true}/>
                                <span>Yes</span>
                                <input id="uppercase-false" name="uppercase" type="radio" value={false} onChange={(e) => this.updateAnswerType(e, e.target.name)} required checked={this.state.answerFormat.uppercase === "false" ||  this.state.answerFormat.uppercase === false}/>
                                <span>No</span>
                            </div>
                        </div>

                        <div className="field">
                            <label>Has medical follow ups?</label>
                            <div className="radio">
                                <input id="has-medical-followups-true" name="hasMedicalFollowUps" type="radio" value={true} onChange={(e) => this.updateAnswerType(e, e.target.name)} checked={this.state.answerFormat.hasMedicalFollowUps === "true" || this.state.answerFormat.hasMedicalFollowUps === true}/>
                                <span>Yes</span>
                                <input id="has-medical-followups-false" name="hasMedicalFollowUps" type="radio" value={false} onChange={(e) => this.updateAnswerType(e, e.target.name)} required checked={this.state.answerFormat.hasMedicalFollowUps === "false" ||  this.state.answerFormat.hasMedicalFollowUps === false}/>
                                <span>No</span>
                            </div>
                        </div>

                        <div className="field">
                            <label>Language on pdf?</label>
                           <select name="language" onChange={(e => this.updateAnswerType(e, e.target.name))} value={this.state.answerFormat.language}>
                               {
                                   this.props.fields.language.options.map(option => (
                                       <option value={option.key}>{option.label}</option>
                                   ))
                               }
                           </select>
                        </div>

                        <div className="field">
                            <label>Is insured 1 always a dependant?</label>
                            <div className="radio">
                                <input id="is-insured-one-dependant-true" name="isInsuredOneDependantOne" type="radio" value={true} onChange={(e) => this.updateAnswerType(e, e.target.name)} checked={this.state.answerFormat.isInsuredOneDependantOne === "true" || this.state.answerFormat.isInsuredOneDependantOne === true}/>
                                <span>Yes</span>
                                <input id="is-insured-one-dependant-false" name="isInsuredOneDependantOne" type="radio" value={false} onChange={(e) => this.updateAnswerType(e, e.target.name)} required checked={this.state.answerFormat.isInsuredOneDependantOne === "false" ||  this.state.answerFormat.isInsuredOneDependantOne === false}/>
                                <span>No</span>
                            </div>
                        </div>

                        <div className="field">
                            <label>Do medical questions need to ask for details on every insured person?</label>
                            <div className="radio">
                                <input id="medical-questions-for-multiple-insured-true" name="medicalQuestionsForMultipleInsured" type="radio" value={true} onChange={(e) => this.updateAnswerType(e, e.target.name)} checked={this.state.answerFormat.medicalQuestionsForMultipleInsured === "true" || this.state.answerFormat.medicalQuestionsForMultipleInsured === true}/>
                                <span>Yes</span>
                                <input id="medical-questions-for-multiple-insured-false" name="medicalQuestionsForMultipleInsured" type="radio" value={false} onChange={(e) => this.updateAnswerType(e, e.target.name)} required checked={this.state.answerFormat.medicalQuestionsForMultipleInsured === "false" ||  this.state.answerFormat.medicalQuestionsForMultipleInsured === false}/>
                                <span>No</span>
                            </div>
                        </div>
                    </div>
				</div>
			</form>
		)
	}
}


export default Form;