import React from 'react';
import API from '../../../../services/api.jsx'
import Loader from '../../../layout/loader.jsx'
import Table from '../table.jsx'
// import Content from '../../../layout/content.jsx'
// import Head from '../../../layout/head.jsx'
// import { toast } from 'react-toastify'
import Modal from '../modal.jsx'
import QuestionsForPlan from '../../../../services/helpers/questionsForPlan.jsx'

class PersonalList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			selectedQuestion: {},
			questionNumber: '',
			selectedSection: {label: '', value: ''},
			availableQuestions: [],
			tableItems: [
				{
					key: 'personalInformation',
					entities: [],
					label: 'Personal Information'
				},
				{
					key: 'address',
					entities: [],
					label: 'Address'
				},
				{
					key: 'contact',
					entities: [],
					label: 'Contact'
				}, 
				{
					key: 'extraInformation',
					entities: [],
					label: 'Extra Information'
				}
			]
		}
	}

	componentDidMount() {
		this.loadEntities()
	}


	loadEntities = () => {
		API.list('questions').then(availableQuestions => {
			this.setState({loading: true})
			this.getQuestionsFromPlans(availableQuestions)
		}).catch(err => {
			console.log(err, ' error when fetching questions in general')
		})
	}

	hidePopup = () => {
		this.setState({
			...this.state,
			popupVisible: false
		})
	}

    openPopup = (key, label) => {
		this.setState({
			...this.state,
			popupVisible: true,
			selectedSection: {value: key, label: label}
		})
	}
	
	getQuestionsFromPlans(availableQuestions) {
		API.getQuestionsFromPlan(this.props.match.params.planId, 'personal').then(questionsForPlan => {
			QuestionsForPlan.sortMultipleTableItems(questionsForPlan).then(tableItems => {
				QuestionsForPlan.sortAvailableQuestions(availableQuestions).then(sortedQuestions => {
					this.setState({
						...this.state,
						tableItems: tableItems,
						availableQuestions: sortedQuestions,
						loading: false
					})
				})
			})
		}).catch(err => {
			console.log(err, ' error when fetching questions from plan')
		})
	}

	render() {
		const sharedProps = {
			section:'personal',
			planId: this.props.match.params.planId
		}

		if(this.state.loading)
			return <Loader />
		else {
			return (
			<>
				{
					this.state.tableItems.map((tableItem, index) => (
						<>
							{index === 0 ? <h3 className="above-table" style={{color: 'white'}}>{tableItem.label}</h3> : <h3 className="above-table">{tableItem.label}</h3>}

							<Table
								id={tableItem.key}
								label={tableItem.label}
								entities={tableItem.entities} 
								openPopup={this.openPopup}
								loadEntities={this.loadEntities}
								{...sharedProps}
							/>
						</>
					))
				}

				<Modal 
					hidePopup={this.hidePopup}
					popupVisible={this.state.popupVisible}
					availableQuestions={this.state.availableQuestions}
					selectedSection={this.state.selectedSection}
					{...sharedProps}
				/>
			</>

			)
		}
	}
}

export default PersonalList;