import React from 'react';
import { NavLink } from "react-router-dom";
import User from '../../services/user.jsx'
import { useHistory } from "react-router-dom"

import { connect } from 'react-redux'
import { logout } from '../../redux';

function Nav(props) {
	let history = useHistory()

	function logout(e) {
		e.preventDefault()
		props.logout()
		User.logout()
		history.push('/login')
	}
	
	return (
		<nav>
			<ul>
				{ User.hasRole('admin') &&
					<li>
						<NavLink activeClassName="active" to="/operators">
							Operators
						</NavLink>
					</li>
				}
				{
					User.hasRole('admin') && 
					<li>
						<NavLink activeClassName="active" to="/pages">
							Pages
						</NavLink>
					</li>
				}
				<li>
					<NavLink activeClassName="active" to="/providers">
						Providers
					</NavLink>
				</li>
				<li>
					<NavLink activeClassName="active" to="/applications">
						Applications
					</NavLink>
				</li>
				{ User.hasRole('superAdmin') &&
					<li>
						<NavLink activeClassName="active" to="/questions">
							Questions
						</NavLink>
					</li>
				}
				<li>
					<a href="#" onClick={logout}>
						Logout
					</a>
				</li>
			</ul> 
		</nav>
	);
}

function mapStateToProps(state) {
    return {
       loggedIn: state.loggedIn
    };
}

const mapDispatchToProps = dispatch => {
    return {
		logout: () => dispatch(logout())
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Nav)
