import React from 'react';
import SelectNationality from '../../../services/helpers/nationality';
import FormActions from '../formActions'

class ApplicationEmergencyForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = props.fields
	}

	updateField = (key, value) => {
		this.setState({...this.state, [key]: value })
	}

	onSubmit(e, callback) {
		e.preventDefault()
		callback()
	}

	render() {
		return (
			<form onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state))}>
				<FormActions />

				<div className="block">
					<h2>Emergency Contact</h2>
					<div className="fields clearfix wrap">
						<div className="field"> 
							<label>First name</label>
							<input type="text" name="emergencyFirstName" onChange={(e) => this.updateField(e.target.name, e.target.value)} value={this.state.emergencyFirstName}/>
						</div>
						<div className="field">
							<label>Middle name</label>
							<input type="text" name="emergencyMiddle" onChange={(e) => this.updateField(e.target.name, e.target.value)} value={this.state.emergencyMiddle}/>
						</div>
						<div className="field">
							<label>Surname</label>
							<input type="text" name="emergencySurname" onChange={(e) => this.updateField(e.target.name, e.target.value)} value={this.state.emergencySurname}/>
						</div>
						<div className="field"> 
							<label>Email</label>
							<input type="email" name="emergencyEmail" onChange={(e) => this.updateField(e.target.name, e.target.value)} value={this.state.emergencyEmail}/>
						</div>
						<div className="field"> 
							<label>Mobile</label>
							<input type="text" name="emergencyMobile" onChange={(e) => this.updateField(e.target.name, e.target.value)} value={this.state.emergencyMobile}/>
						</div>
						<div className="field"> 
							<label>Home number</label>
							<input type="text" name="emergencyHome" onChange={(e) => this.updateField(e.target.name, e.target.value)} value={this.state.emergencyHome}/>
						</div>
						<div className="field"> 
							<label>Work number</label>
							<input type="text" name="emergencyWork" onChange={(e) => this.updateField(e.target.name, e.target.value)} value={this.state.emergencyWork}/>
						</div>
						<div className="field">
							<label>Relationship to contact</label>
							<input type="text" name="emergencyRelationship" onChange={(e) => this.updateField(e.target.name, e.target.value)} value={this.state.emergencyRelationship}/>
						</div>
						<div className="field textarea"> 
							<label>Address</label>
							<textarea name="emergencyHomeAddress" onChange={(e) => this.updateField(e.target.name, e.target.value)} value={this.state.emergencyHomeAddress}/>
						</div>
						<div className="field">
							<label>Country of Residence</label>
							<SelectNationality updateField={(value, name) => this.updateField(name, value)} name={"emergencyCountry"} field value={this.state.emergencyCountry}/>
						</div>
					</div>
				</div>
			</form>
		)
	}
}


export default ApplicationEmergencyForm;