import React from 'react';
import API from '../../../../services/api.jsx'
import Loader from '../../../layout/loader.jsx'
import Table from '../table.jsx'
import { toast } from 'react-toastify'
import Modal from '../modal.jsx'
import QuestionsForPlan from '../../../../services/helpers/questionsForPlan'

class DependantsList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			selectedQuestion: {},
			questionNumber: '',
			selectedSection: {label: '', value: ''},
			availableQuestions: [],
			maxNoOfDependants: 1,
			tableItems: [
				{
					key: 'questions',
					entities: [],
					label: 'Dependants'
				}
			]
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities = () => {
		API.list('questions').then(availableQuestions => {
			this.setState({loading: true})
			this.getQuestionsFromPlans(availableQuestions)
		}).catch(err => {
			console.log(err, ' error when fetching questions in general')
		})
	}

	hidePopup = () => {
		this.setState({
			...this.state,
			popupVisible: false
		})
	}

    openPopup = (key, label) => {
		this.setState({
			...this.state,
			popupVisible: true,
			selectedSection: {value: key, label: label}
		})
	}

	updateMaxNumber(e) {
		this.setState({...this.state, maxNoOfDependants: e.target.value})
		console.log(this.state.maxNoOfDependants)
	}
	
	getQuestionsFromPlans(availableQuestions) {
		API.getQuestionsFromPlan(this.props.match.params.planId, 'dependants').then(questionsForPlan => {
			QuestionsForPlan.sortTableItems(questionsForPlan.questions, 'Dependants', 'questions').then(tableItems => {
				QuestionsForPlan.sortAvailableQuestions(availableQuestions).then(sortedQuestions => {
					this.setState({
						...this.state,
						tableItems: tableItems,
						availableQuestions: sortedQuestions,
						maxNoOfDependants: questionsForPlan.maxNoOfDependants,
						loading: false
					})
				})
			})
		}).catch(err => {
			console.log(err, ' error when fetching questions from plan')
		})
	}

    saveMaxNumberOfDependants = value => {
        let obj = {
           maxNoOfDependants: value
        }

        API.putsMaxNoOfDependants(this.props.match.params.planId, obj).then(res => {
			console.log(res)
			toast.success(`Max number of dependants updated`);
		}).catch(err => {
			console.log(err)
			toast.error('An unexpected error has occured, please try again');
		})
    }

	render() {
		const sharedProps = {
			section:'dependants',
			planId: this.props.match.params.planId
		}

		if(this.state.loading)
			return <Loader />
		else {
			return (
                <>
					<div className="block above dependants">
						<form onSubmit={(e) => {e.preventDefault(); this.saveMaxNumberOfDependants(this.state.maxNoOfDependants)}}>
							<div className="actions clearfix">
								<div className="buttons clearfix">
									<button className="blue">Save</button>
									</div>
								</div>
							<div className="fields clearfix">
								<div className="field">
									<label>Max no of people who can be insured (including the applicant if necessary): </label>
									<input required onChange={(e) => this.updateMaxNumber(e)} type="number" value={this.state.maxNoOfDependants} />
								</div>
							</div>
						</form>
					</div>

					<Table
						id={this.state.tableItems[0].key}
						label={this.state.tableItems[0].label}
						entities={this.state.tableItems[0].entities} 
						openPopup={this.openPopup}
						loadEntities={this.loadEntities}
						{...sharedProps}
					/> 

					<Modal 
						hidePopup={this.hidePopup}
						popupVisible={this.state.popupVisible}
						availableQuestions={this.state.availableQuestions}
						selectedSection={this.state.selectedSection}
						{...sharedProps}
					/>
				</>
			)
		}
	}
}

export default DependantsList;