import React from 'react';
import API from '../../services/api.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import Loader from '../layout/loader.jsx'

class PagesList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: false,
			pages: {
				columns: [{
					label: 'Pages',
					key: 'label'
				}, {
					label: 'Page ID',
					key: 'id'
				}, {
					realm: 'admin',
					action: 'edit',
					onClick: (entityId) => {
						const entities = this.state.pages.entities
						const pageToGoTo = entities.filter(entity => entity.id === entityId)
						const model = pageToGoTo[0].model

						this.props.history.push('/pages/' + model + '/' + entityId + '/edit')
					},
				}],
				entities: [],
				filters: [],
				actions: [],
					// {
				// 	classList: 'blue add',
				// 	label: 'New page',
				// 	onClick: () => {
				// 		this.props.history.push('/pages/new')
				// 	}
				// }
				selectedItems: [],
				multiSelect: false
			}
		}
	}

	componentDidMount() {
        this.loadEntities()
    }
    
    loadEntities() {
        API.getPages().then(entities => this.setState({ ...this.state, loading: false, pages: {...this.state.pages, entities: entities} }))
    }

	render() {
		if(this.state.pages.entities.length === 0)
			return <Loader />
		return <>
			<Head title={'Pages'} />
			<Content> 
                <Table settings={this.state.pages}/>		
			</Content> 
		</>
	}
}

export default PagesList;