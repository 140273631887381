import React from 'react';
import API from '../../services/api.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import Loader from '../layout/loader.jsx'
import moment from 'moment'
import { toast } from 'react-toastify'

class PlansList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			filters: {
                provider: this.props.match.params.providerId
			},
			breadcrumb: { title: 'Back to company list', path: '/providers' },
			plans: {
				columns: [{
					label: 'Plan name',
					key: 'name'
				}, {
					label: 'Updated',
					key: 'updatedAt',
						view: function(field){
							return moment(field).format('YYYY-MM-DD')
						},
				}, 
				{
					action: 'edit plan and questions and delete',
					realm: 'superAdmin',
					onClickEdit: (id) => {
						this.props.history.push('/providers/' + this.props.match.params.providerId + '/plans/' + id + '/edit')
					},
					onClickEditQuestions: (id) => {
						this.props.history.push('/providers/' + this.props.match.params.providerId + '/plans/' + id + '/questions/personal')
					},
					onClickDelete: id => {
						var r = window.confirm("Are you sure you want to delete this plan?");
						if (r === false) return

						this.setState({ loading: true })
						API.delete('plans', id).then(() => {
						    toast.success('Plan was successfully deleted')
						    this.loadEntities()
						}).catch(() => {
						    toast.error('An unexpected error has occured')
						    this.setState({ loading: false })
						})
					}
				}, 
				],
                entities: [],
				filters: [],
				actions: [
                {
					classList: 'blue add',
					label: 'New plan',
					realm: 'superAdmin',
					onClick: () => {
						this.props.history.push(`/providers/${this.props.match.params.providerId}/plans/new`)
					}
				}],
				selectedItems: [],
				multiSelect: false,
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
        API.getPlansFromProvider(this.state.filters.provider).then(planProvider => {
			this.setState({ ...this.state, loading: false, plans: {...this.state.plans, entities: planProvider.plans}, provider: planProvider.provider })
		})
	}

	viewPlan(id) {
		this.props.history.push('/providers/' + this.props.match.params.providerId + '/plans/' + id);
    }

	render() {
		if(this.state.loading)
			return <Loader />
		return <>
			<Head title={this.state.provider} editRole={'superAdmin'} edit={() => this.props.history.push('/providers/' + this.props.match.params.providerId)} breadcrumb={this.state.breadcrumb} />
			<Content>
				<Table settings={this.state.plans} />				
			</Content>
		</>
	}
}

export default PlansList;