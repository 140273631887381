import React from 'react';
import FormActions from '../formActions'

class ApplicationOtherPlansForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = props.questions
	}

	updateField = (key, value) => {
		this.setState({...this.state, [key]: value })
	}

	onSubmit(e, callback) {
		e.preventDefault()
		callback()
	}

	render() {
		return (
			<form onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state))}>
				<FormActions />
				<div className="block">
                   <h2>Other plans</h2>
                    <div className="checkboxes">
						<div className={"checkbox-wrap" + (this.state.travel ? ' checked' : '')}>
                            <div className="content">
                                <input type="checkbox" name="travel" onChange={(e) => this.updateField(e.target.name, e.target.checked)} checked={this.state.travel}/>
                                <p>Travel insurance</p>
                            </div>
                        </div>

                        <div className={"checkbox-wrap" + (this.state.home ? ' checked' : '')}>
                            <div className="content">
                                <input type="checkbox" name="home" onChange={(e) => this.updateField(e.target.name, e.target.checked)} checked={this.state.home}/>
                                <p>Home insurance</p>
                            </div>
                        </div>

                        <div className={"checkbox-wrap" + (this.state.life ? ' checked' : '')}>
                            <div className="content">
                                <input type="checkbox" name="life" onChange={(e) => this.updateField(e.target.name, e.target.checked)} checked={this.state.life}/>
                                <p>Life insurance</p>
                            </div>
                        </div>

						<div className={"checkbox-wrap" + (this.state.critical ? ' checked' : '')}>
                            <div className="content">
                                <input type="checkbox" name="critical" onChange={(e) => this.updateField(e.target.name, e.target.checked)} checked={this.state.critical}/>
                                <p>Critical illness</p>
                            </div>
                        </div>
                    </div>
				</div>
			</form>
		)
	}
}


export default ApplicationOtherPlansForm;