import React from 'react';
import Loader from '../layout/loader.jsx'
import PrivateRoute from '../layout/privateRoute'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import ApplicationPlansEdit from './plans/edit.jsx';
import ApplicationPersonalEdit from './personal/edit.jsx';
import ApplicationDependantsEdit from './dependants/edit.jsx';
import ApplicationDoctorEdit from './doctor/edit.jsx';
import ApplicationEmergencyEdit from './emergency/edit.jsx';
import ApplicationOtherPlansEdit from './otherplans/edit.jsx';
import ApplicationMedicalEdit from './medical/edit.jsx';

class ApplicationView extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			breadcrumb: { title: 'Back to Applications', path: '/applications' },
			tabs: {
				param: props.match.params.applicationId,
				links: [
					{ title: 'Plan', path: `/applications/${props.match.params.applicationId}/section/plans` },
					{ title: 'Personal', path: `/applications/${props.match.params.applicationId}/section/personal` },
					{ title: 'Dependants', path: `/applications/${props.match.params.applicationId}/section/dependants` },
					{ title: 'Medical', path: `/applications/${props.match.params.applicationId}/section/medical` },
					{ title: 'Doctor', path: `/applications/${props.match.params.applicationId}/section/doctor` },
					{ title: 'Emergency Contact', path: `/applications/${props.match.params.applicationId}/section/emergency` },
					{ title: 'Other Plans', path: `/applications/${props.match.params.applicationId}/section/other-plans` }
				]
			}
		}
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={this.state.application !== undefined && this.state.application.name !== undefined ? this.state.application.name.first + ' ' + this.state.application.name.last  : 'New application'} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs}/>
				<Content>
					<PrivateRoute exact path="/applications/:applicationId/section/plans" component={ApplicationPlansEdit} />					
					<PrivateRoute exact path="/applications/:applicationId/section/personal" component={ApplicationPersonalEdit} />
					<PrivateRoute exact path="/applications/:applicationId/section/dependants" component={ApplicationDependantsEdit} />
					<PrivateRoute exact path="/applications/:applicationId/section/medical" component={ApplicationMedicalEdit} />
					<PrivateRoute exact path="/applications/:applicationId/section/doctor" component={ApplicationDoctorEdit} />
					<PrivateRoute exact path="/applications/:applicationId/section/emergency" component={ApplicationEmergencyEdit} />
					<PrivateRoute exact path="/applications/:applicationId/section/other-plans" component={ApplicationOtherPlansEdit} />
				</Content>
			</>
	}
}

export default ApplicationView;