// import { update } from 'lodash';
import React from 'react';
import UncontrolledEditor from '../editor.jsx';
import FileHandler from '../../layout/file-handler/fileHandler.jsx'
import Loader from '../../layout/loader.jsx'

class OurplansForm extends React.Component {
    constructor(props) {
		super(props)
        const entity = props.entity

		this.state = {
            entity
		}
    }

    createCorrectUrl = (arrayOfImages) => {
        let urlPrefix = 'storage/pages-images/'

        const newArray = arrayOfImages.map(image => {
            let urlEnd = image.uri
            let url = urlPrefix + urlEnd

            image.uri = url
            return image
        })

        return newArray
    }

    componentDidMount() {
        const entity = this.props.entity

        this.setState({
            entity,
            temporaryImages: {
                carouselMain: this.createCorrectUrl(entity.carouselMain),
                companyLogos: this.createCorrectUrl(entity.companyLogos)
            }
        })
    }

    showImage = (file, fileObj, type) => {
        let urlPrefix = 'storage/temp-images/'
        let url = urlPrefix + fileObj.uri

        fileObj.file = file
        fileObj.uri = url

        if(type === 'carouselMain') {
            const carouselMain = [...this.state.temporaryImages.carouselMain, fileObj]

            this.setState(previousState => ({
                ...previousState.entity,
                temporaryImages: {
                    companyLogos: [...previousState.temporaryImages.companyLogos],
                    carouselMain
                }
            }));
        } else {
            const companyLogos = [...this.state.temporaryImages.companyLogos, fileObj]

            this.setState(previousState => ({
                ...previousState.entity,
                temporaryImages: {
                    companyLogos,
                    carouselMain: [...previousState.temporaryImages.carouselMain]
                }
            }));
        }
    }

    removeImage = (imageId, type) => {
        const temporaryImages = {...this.state.temporaryImages}
        if(type === 'carouselMain') {
            const carouselMain = temporaryImages[type].filter(image => image._id !== imageId)

            this.setState(previousState => ({
                ...previousState.entity,
                temporaryImages: {
                    companyLogos: [...previousState.temporaryImages.companyLogos],
                    carouselMain
                }
            }))
        } else {
            const companyLogos = temporaryImages[type].filter(image => image._id !== imageId)

            this.setState(previousState => ({
                ...previousState.entity,
                temporaryImages: {
                    carouselMain: [...previousState.temporaryImages.carouselMain],
                    companyLogos
                }
            }))
        }
    }

    handleChange = (e, type, key, index = 0, editor = false) => {
        let newValue = e
        let { entity } = this.state

        if(editor) {
            entity[type][key] = newValue
        } else {
            newValue = newValue.target.value
            entity[type][index][key] = newValue
        }

        this.setState(previousState => ({
            ...previousState,
            entity
        }))
    }

	render() {
        const { entity } = this.state
        
        if(this.state.temporaryImages === undefined)
            return <Loader />
        else
		return <>
			<form onSubmit={(e) => { 
                e.preventDefault(); 
                this.props.save({...this.state})
               }
            } className="custom-form">
				<div className="actions clearfix">
					<div className="buttons clearfix">
						<button className="grey" onClick={(e) => { e.preventDefault(); this.props.cancel() }}>Cancel</button>
						<button className="blue">Save page</button>
					</div>
				</div>

				<div className="block above">
                    <div className="fields clearfix">
                        <h2>Main Carousel</h2>
                        <div className="file-handler-container">
                            {this.state.temporaryImages.carouselMain.map((image) => (
                                <div className="field">
                                    <FileHandler
                                        image={image.uri}
                                        name={image.name}
                                        id={image._id}
                                        removeImage={(type) => this.removeImage(image._id, type)}
                                        type={'carouselMain'}
                                        label={'Image'}
                                        fileType={'images'}
                                    />
                                </div>
                            ))}

                            <FileHandler 
                                showImage={this.showImage}
                                type={'carouselMain'}
                                fileType={'images'}
                                dropzone
                            />
                        </div>
                    </div>

                    <div className="fields clearfix">
                        <h2>Company Logos</h2>
                        <div className="file-handler-container">
                            {
                                this.state.temporaryImages.companyLogos.map((logo) => {
                                    return (
                                            <div className="field">
                                                <FileHandler
                                                    image={logo.uri}
                                                    name={logo.name}
                                                    imageId={logo._id}
                                                    removeImage={(type) => this.removeImage(logo._id, type)}
                                                    type={'companyLogos'}
                                                    fileType={'images'}
                                                />
                                            </div>
                                    )
                                })}

                                <FileHandler 
                                    showImage={this.showImage}
                                    type={'companyLogos'}
                                    fileType={'images'}
                                    dropzone
                                />
                        </div>
                    </div>

                    <div className="fields container">
                        <h2>Description text</h2>
                        <div className="field">
                            <label>English description text</label>
                            <UncontrolledEditor 
                                text={entity.longDescription.english} 
                                currentContent={(newText) => this.handleChange(newText, 'longDescription', 'english', 0, true)}
                            />
                        </div>
                    
                        <div className="field">
                            <label>Chinese description text</label>
                            <UncontrolledEditor 
                                text={entity.longDescription.chinese} 
                                currentContent={(newText) => this.handleChange(newText, 'longDescription', 'chinese', 0, true)}
                            />
                        </div>
                    </div>
				</div>
			</form>
		</>
	}
}

export default OurplansForm;