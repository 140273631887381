import React from 'react';
import Table from '../../layout/table.jsx'
// import Content from '../../../layout/content.jsx'
// import Head from '../../../layout/head.jsx'
import { toast } from 'react-toastify'
import API from '../../../services/api.jsx'

class TablePlansQuestions extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			[props.key]: {
				columns: [
					{
						label: 'ID#',
						key: 'value'
					}, 
					{
						label: 'Answer required',
						key: 'answerType'
					},
					{
						action: 'delete',
						onClick: (entityId) => {
                            var r = window.confirm("Are you sure you want to this question?");
                            if (r === false) return

                            this.removeQuestion(entityId, props.id)
							props.loadEntities()
						}
					},
					{
						action: 'up and down',
						moveUp: (entityId) => {
							const currentIndex = props.entities.findIndex(e => e.id === entityId)
							if(currentIndex !== 0 ) {
								const reorderedEntities = this.reorderArray(props.entities, currentIndex, currentIndex - 1)
								this.setState({ [props.key]:{ ...this.state[props.key], entities: reorderedEntities }})
							}
						},
						moveDown: (entityId) => {
							const currentIndex = props.entities.findIndex(e => e.id === entityId)
							if(currentIndex !== props.entities.length - 1) {
								const reorderedEntities = this.reorderArray(props.entities, currentIndex, currentIndex + 1)
								this.setState({[props.key]:{ ...this.state[props.key], entities: reorderedEntities }})
							}
						}
					}
				],
				entities: props.entities,
				filters: [{
					label: 'Search for a question',
					onChange: value => {
						// eslint-disable-next-line array-callback-return
						const filteredEntities = this.state.originalEntities.filter(q => {
							if(value === "") {
								return q
							} else if(q.value.includes(value)) {
								return q
							}
						})

						this.setState({
							[this.props.key]: {
								...this.state[this.props.key],
								entities: filteredEntities
							}
						})
					}
				}],
				actions: [
					{
						classList: 'grey',
						label: 'Sync order',
						onClick: () => {
							const entitiesWithIds = this.state[props.key].entities.map(question => {
								return (
									{
										question: question.id,
										questionNumber: null
									}
								)
							})

							API.syncQuestionOrder(props.planId, props.section, entitiesWithIds, props.section === 'personal' || props.section === 'dependants' ? props.id : null).then(res => {
								console.log(res)
								toast.success(`Questions were successfully reordered`);
							})
							.catch(err => {
								console.log(err)
								toast.error('An unexpected error has occured, please try again');
							})
						}
					},
					{
						classList: 'blue add',
						label: 'New question',
						onClick: () => {
                            this.props.openPopup(props.id, props.label)
						}
					}
				],
				selectedItems: [],
				multiSelect: false
			}
		}
	}
	
	componentDidMount() {
		this.setState({originalEntities: this.props.entities})
	}

	reorderArray(array, from, to) {
		if( to === from ) return array;
	  
		var target = array[from];                         
		var increment = to < from ? -1 : 1;
	  
		for(var k = from; k !== to; k += increment){
		  array[k] = array[k + increment];
		}
		array[to] = target;
		return array;
	  }
	  
    
	removeQuestion = (entityId, key) => {
		let obj = {
			[key]: entityId
		}

		API.deleteQuestionFromPlan(this.props.planId, this.props.section, obj)
			.then(res => {
				console.log(res)
				toast.success(`Question successfully removed from section`);
			})
			.catch(err => {
				console.log(err)
				toast.error('An unexpected error has occured, please try again');
			})
	}

	render() {
        return (
        <>
            <Table 
                settings={this.state[this.props.key]} 
            />
        </>
        )
    }
}

export default TablePlansQuestions;