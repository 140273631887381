class QuestionsForPlan {    
    sortAvailableQuestions = (availableQuestions) => {
        return new Promise((res, rej) => {
            let updatedAvailable = []
            availableQuestions.map(question => {
                delete question.options
                return updatedAvailable.push(question)
            })
            updatedAvailable.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
            res(updatedAvailable)
        })
    }
    
    sortTableItems = (tableItems, label, key, type = 'normal') => {
        return new Promise(async (res, rej) => {
            let tableItemArray = []
            let entities = []
        
            await tableItems.map(question => {
                if(question.question !== null)
                    entities.push(question.question)
        
                return entities
            })
            
            let obj = {
                key: key,
                entities,
                label: label
            }
        
            tableItemArray.push(obj)

            res(tableItemArray)
        })
    }

    sortMultipleTableItems = (tableItems) => {
        return new Promise((res, rej) => {
            let tableItemArray = []
    
            if(Object.keys(tableItems).length !== 0) {
                Object.keys(tableItems).map(async tableItem => {
                    let labelSplit = tableItem.split(/(?=[A-Z])/).join(" ")
                    let label = labelSplit[0].toUpperCase() + labelSplit.slice(1)
                    await this.sortTableItems(tableItems[tableItem], label, tableItem).then(item => {
                        tableItemArray.push([].concat(...item))
                    })
                    res([].concat(...tableItemArray))
                })

            }	
        })
    }

}

export default new QuestionsForPlan()