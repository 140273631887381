import React from 'react';
import FormActions from '../formActions'

class ApplicationPlansForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			providers: props.entity,
			selected: {
				provider: props.selected.provider,
				plan: props.selected.plan,
				options: props.selected.provider.plans
			}
		}
	}

	updateSelected = (e, type) => {
		if(type === 'provider') {
			let providers = this.state.providers
	
			for(let i in providers) {
				let provider = providers[i]
	
				if(provider.id === e.target.value) {
					this.setState({
						...this.state,
						selected: {
							provider,
							options: provider.plans,
							plan: provider.plans[0]
						}
					})
					break
				} 
			}
		} else {
			let planId = e.target.value
			let provider = this.state.selected.provider
			let providersPlans = provider.plans

			let selectedPlan = providersPlans.filter(plan => plan.id === planId)
			
			this.setState({
				...this.state,
				selected: {
					...this.state.selected,
					plan: selectedPlan[0]
				}
			})
		}
	}

	updateBoolean(value, key) {
		this.setState({ [key]: value })
	}

	onSubmit(e, callback) {
		e.preventDefault()
		callback()
	}

	render() {
		return (
			<form onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state.selected))}>
				<FormActions />

				<div className="block">
					<h2>Plan Information</h2>
					<div className="fields clearfix">
						<div className="field">
							<label>Provider</label>
								<select onChange={(e) => this.updateSelected(e, 'provider')} required={true} value={this.state.selected.provider.id}>
									{this.state.providers.map((provider) => {
										return <option value={provider.id}>{provider.name}</option>
									})}
								</select>
						</div>

						<div className="field">
							<label>Plan</label> 
								<select onChange={(e) => this.updateSelected(e, 'plan')} required={true} value={this.state.selected.plan.id}>
									{this.state.selected.provider.id !== '' &&
										this.state.selected.provider.plans.map((plan) => {
											return <option value={plan.id}>{plan.name}</option>
										})
									}
								</select> 
						</div>
					</div>
				</div>
			</form>
		)
	}
}


export default ApplicationPlansForm;