import React, { Component } from 'react';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class UncontrolledEditor extends Component {
    constructor(props) {
      super(props);
      this.state = {
        editorState: EditorState.createWithContent(ContentState.createFromText(this.props.text)),
        contentState: ''
      };
    }
  
    onEditorStateChange=(editorState)=> {
      this.setState({
        editorState
      });
    };

    onContentStateChange= (contentState) => {
        this.setState({
          contentState: contentState.blocks[0].text
        });

        console.log(this.state.contentState)
        this.props.currentContent(this.state.contentState)
    };
  
    render() {
      const { editorState } = this.state;

      return (
        <Editor
            toolbar={{options: []}}
            editorState={editorState}
            wrapperClassName="editor-wrapper"
            editorClassName="editor-container"
            onEditorStateChange={this.onEditorStateChange}
            onContentStateChange={this.onContentStateChange}
        />
      )
    }
  }

export default UncontrolledEditor