import React from 'react';
import Loader from '../../layout/loader.jsx'
import API from '../../../services/api.jsx'
// import ApplicationPersonalForm from './form.jsx';
// import moment from 'moment'
import { toast } from 'react-toastify'
import ApplicationMedicalForm from './form.jsx';
import recursiveCreateAnswersArray from '../../../services/helpers/recursiveCreateAnswersArray'

class ApplicationMedicalEdit extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			breadcrumb: { title: 'Back to Applications', path: '/applications' },
			application: null
		}
	}

	// seperate questions so insured1_medical1 is with insured2_medical1 etc.
    parseQuestions = questions => {
        let questionsObj = {}
		let questionsArray = []

		// create a key in questionsObj called insured1 etc.
		questions.forEach(question => {
			questionsObj[question.question.value.match(/medical_[\d]+/)[0]] = []
			return question
		})

		// push the questions into each key in the questionsObj
		questions.map(question => {
			questionsObj[question.question.value.match(/medical_[\d]+/)[0]].push(question.question)
			return question
		})

		Object.keys(questionsObj).map(key => {
			questionsArray.push(questionsObj[key])
			return key
		})

		return questionsArray
    }

	// put answers from each insured person into one array
	parseAnswers = arrayOfAnswers => {
		let newAnswers = []
		for(const i in arrayOfAnswers) {
			const answers = arrayOfAnswers[i].answers

			newAnswers.push(answers)
		}

		return [].concat(...newAnswers)
	}

	// parse out nameAnswers for insured persons
	parseNameAnswers = arrayOfAnswers => {
		let names = []

		for(const i in arrayOfAnswers) {
			let obj = {}
			obj.firstName = arrayOfAnswers[i].firstName
			obj.lastName = arrayOfAnswers[i].lastName

			names.push(obj)
		}

		return [].concat(...names)
	}

	// remove any questions where the person is uninsured
	// for example, an application may have only 1 insured person so remove insured2, insured3 etc.
	removeUninsured = (answers, questions, medicalQuestionsForMultipleInsured) => {
		// number of insured is based on number of insured people on policy. This comes from application.insuredPersons in backend
		const numberOfInsured = medicalQuestionsForMultipleInsured ? answers.length : 1
		const insuredPersonsQuestions = []
		for(const i in answers) {
			let insuredPerson = new RegExp(`insured${parseInt(i)+1}`)
			const uninsured = parseInt(i) > numberOfInsured
			const keepQuestions = questions.filter(q => q.question !== null && q.question.value.match(insuredPerson))

			if(uninsured) {
				break
			}

			insuredPersonsQuestions.push([].concat(...keepQuestions))
		}

		return [].concat(...insuredPersonsQuestions)
	}

	// return only value for follow up questions
	parseFollowUps = followUps => {
		const parsedFollowUps = followUps.map(answers => {
			return answers.value
		})

		return parsedFollowUps
	}

	componentDidMount() {
		this.setState({loading: true})
		const applicationId = this.props.match.params.applicationId

		API.getQuestionsAndApplication(applicationId, 'medical').then(result => {
			let nameAnswers = this.parseNameAnswers(result.insuredPersons)
			let insuredPersonsQuestions = this.removeUninsured(result.insuredPersons, result.questions, result.medicalQuestionsForMultipleInsured)
			let answers = this.parseAnswers(result.insuredPersons)

			recursiveCreateAnswersArray(answers, insuredPersonsQuestions).then(answersWithIds => {
				const updatedAnswers = [].concat(...answersWithIds)
				const updatedQuestions = this.parseQuestions(insuredPersonsQuestions)
				const parseFollowUps = this.parseFollowUps(result.followUpAnswers)
				this.setState({questions: updatedQuestions, answers: updatedAnswers, nameAnswers, followUpQuestionTemplate: result.hasMedicalFollowUps ? result.followUpQuestions[0].question.label : {english: 'Please provide more information', chinese: 'Please provide more information'}, followUpAnswers: parseFollowUps, hasMedicalFollowUps: result.hasMedicalFollowUps, medicalQuestionsForMultipleInsured: result.medicalQuestionsForMultipleInsured})
			})
		})
		.catch(err => {
			toast.error("There was a problem fetching the questions");
			console.log(err)
		})
	}

	// split into insured1, insured2, insured3 etc. questions
	splitIntoInsured = (answers, questionsArrays) => {
		let questionsObj = {}
		let answersArray = []

		questionsArrays.forEach(questions => {
			for(const i in questions) {
				const question = questions[i]

				for(const j in answers) {
					const answer = answers[j]

					if(answer.questionId === question.id) {
						questionsObj[question.value.match(/insured[\d]/)[0]] = []
					}
				}
			}
		})

		questionsArrays.forEach(questions => {
			for(const i in questions) {
				const question = questions[i]

				for(const j in answers) {
					const answer = answers[j]

					if(answer.questionId === question.id) {
						questionsObj[question.value.match(/insured[\d]/)[0]].push(answer)
					}
				}
			}
		})

		Object.keys(questionsObj).map(key => {
			answersArray.push(questionsObj[key])
			return key
		})

		return answersArray
	}

	sortFollowUps = followUpAnswers => {
		const keys = Object.keys(followUpAnswers)
		const followUpsArray = []

		for(const i in keys) {
			const key = keys[i]
			const insuredNumber = parseInt(key.slice(-1)) + 1
			const insuredPerson = 'insured' + insuredNumber
			if(followUpAnswers[key] !== '') {
				followUpsArray.push({insuredPerson: insuredPerson, value: followUpAnswers[key]})
			}
		}

		return followUpsArray
	}

	// split into insured1, insured2, insured3 etc. for answers (make into a nested array)
	async splitAnswersIntoInsured(answers, questionsArray) {
		let questionsObj = {}
		let answersArray = []

		// create empty array as key
		for(const i in questionsArray) {
			const question = questionsArray[i]
			for(const j in answers) {
				const answer = answers[j]
				if(question !== null) {
					if(answer.questionId === question.id) {
						questionsObj[question.value.match(/insured[\d]/)[0]] = []
					}
				}
			}
		}

		// add answers to key
		for(const i in questionsArray) {
			const question = questionsArray[i]

			for(const j in answers) {
				const answer = answers[j]
				if(question !== null) {
					if(answer.questionId === question.id) {
						questionsObj[question.value.match(/insured[\d]/)[0]].push(answer)
					}
				}
			}
		}

		// remove key values from object and put into nested arrays
		Object.keys(questionsObj).map(key => {
			answersArray.push(questionsObj[key])
			return key
		})

		return answersArray
	}

	// put all questions and follow up questions into one array of objects
	getAllQuestions = (questionsArrays) => {
		return new Promise(async(res, rej) => {
			const questionsArraysFlattened = [].concat(...questionsArrays)
			const insuredPersonsArray = []

			for(const i in questionsArraysFlattened) {
				const question = questionsArraysFlattened[i];
				let hasOptions =  question.answerType === 'radio' || question.answerType === 'select' || question.answerType === 'nationality' || question.answerType === 'checkbox';

				if(question !== null) {
					if(question.answerType === 'address') {
						await this.getAllQuestions(question.addressQuestions).then(questions => {
							insuredPersonsArray.push(questions)
						})
					} else {
						insuredPersonsArray.push(question)
					}
				} 
	
				if (hasOptions) {
					let options = question.options
	
					for (const j in options) {
						const option = options[j];
						const noFollowUps = (option.followUps === null || option.followUps === undefined) || option.followUps.questions === null || option.followUps.questions === [] || option.followUps.questions[0] === null ||
						option.followUps.questions.length === 0
	
						if (noFollowUps) {
						} else {
							await this.getAllQuestions(option.followUps.questions).then(question => {
								insuredPersonsArray.push(question)
							})
						}
					}
				}
			}

			res([].concat(...insuredPersonsArray))
		})
	}

	save = (fields) => {
		this.setState({loading: true})
		this.getAllQuestions(fields.questions).then(questionsArrayNonFlattened => {
			// flatten questions
			const flattenedQuestions = [].concat(...questionsArrayNonFlattened)

			this.splitAnswersIntoInsured(JSON.parse(JSON.stringify(fields.answers)), flattenedQuestions).then(answersArray => {
				const applicationId = this.props.match.params.applicationId
				let followUpAnswers = this.state.hasMedicalFollowUps ? this.sortFollowUps(JSON.parse(JSON.stringify(fields.followUpAnswers))) : []
				let obj = {
					answers: [answersArray],
					followUpAnswers: followUpAnswers,
					medical: true
				}
		
				API.putAnswers(applicationId, obj, 'medical').then(result => {
					this.setState({loading: false, answers: result.answers})
					toast.success("The application has been updated");
				}).catch(err => {
					console.log(err)
					toast.error("There was a problem updating the application");
				})
			})
		})

	}

	render() {

		if(!this.state.questions && !this.state.answers)
			return <Loader />
		else
			return (
				<ApplicationMedicalForm
                    questions={this.state.questions} 
					isProspect={this.state.isProspect}
					noOfDependants={this.state.noOfDependants}
					maxNoOfDependants={this.state.maxNoOfDependants}
					answers={this.state.answers}
					followUpAnswers = {this.state.followUpAnswers}
					followUpQuestionTemplate={this.state.followUpQuestionTemplate}
					hasMedicalFollowUps={this.state.hasMedicalFollowUps}
					nameAnswers={this.state.nameAnswers}
					medicalQuestionsForMultipleInsured={this.state.medicalQuestionsForMultipleInsured}
                    save={this.save}
                />
			)
	}
}

export default ApplicationMedicalEdit;