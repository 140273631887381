import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from './form.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'

class OperatorEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			breadcrumb: { title: 'View operator', path: '/operators'},
			fields: {
				name: { label: 'Name', type: 'text', required: true },
                email: { label: 'Email', type: 'email', required: true },
                password: { label: 'Password', type: 'password', required: true },
                role: { label: 'Role', type: 'select' , 
					options: [{key: 'operator', label: 'Operator'}, {key: 'admin', label: 'Admin'}]
				}
			},
			operator: null
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	componentDidMount() {
        API.getOperator(this.props.match.params.operatorId).then(operator => this.setState({ loading: false, operator: operator }))
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		API.update('users', this.props.match.params.operatorId, fields).then(() => {
			toast.success('Operator has been updated')
			this.props.history.push('/operators/')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Edit operator'} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs} />
				<Content>
					<Form fields={this.state.fields} entity={this.state.operator} cancel={() => this.props.history.push('/operators')} save={this.saveEntity} />
				</Content>
			</>
	}
}

export default OperatorEdit;