import React from 'react'
import ReactDOM from 'react-dom'
import 'resize-observer-polyfill/dist/ResizeObserver.global'
import 'rc-time-picker/assets/index.css'
import 'react-h5-audio-player/lib/styles.css'
import 'react-weekly-schedule/index.css'
import 'react-toastify/dist/ReactToastify.css'
import './styles/index.scss'
import 'rodal/lib/rodal.css'
import Layout from './components/layout/layout'

import { Provider } from 'react-redux';
import store from './redux/Store.js';

ReactDOM.render(
  <Provider store={store}>
    <Layout />
  </Provider>,
document.getElementById('root'));

/**

ReactDOM.render(
  <React.StrictMode>
    <Layout />
  </React.StrictMode>,
  document.body
);

**/