import React from 'react';
import Loader from '../../layout/loader.jsx'
import API from '../../../services/api.jsx'
import ApplicationPlanForm from './form.jsx';
import { toast } from 'react-toastify'

class ApplicationPlansEdit extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			breadcrumb: { title: 'Back to Applications', path: '/applications' },
			application: null,
			providers: null
		}
	}

	componentDidMount() {
        const applicationId = this.props.match.params.applicationId

		API.listProviderPlans().then(providersPlans => {
			API.getQuestionsAndApplication(applicationId, 'plan').then(application => {
				let providersPlansCopy = [...providersPlans]
				let selectedProvider = providersPlansCopy.filter(provider => provider.id === application.plan.provider.id)

				let selected = {
					provider: selectedProvider[0],
					plan: {...application.plan}
				}

				this.setState({ providers: providersPlans, selected, loading: false })
			}).catch(err => {
				toast.error("There was a problem fetching the questions");
				console.log(err)
			})
		})


		.catch(err => {
			console.error(err)
			this.setState({ loading: false })
		})
	}

	save = (fields) => {
		// this.setState({loading: true})
		let obj = {
			plan: fields.plan.id
		}

		API.update('applications', this.props.match.params.applicationId, obj)
			.then(result => {
				this.setState({
					application: result,
					loading: false
				})
				toast.success('Application updated successfullt')
			})
			.catch(err => {
				console.log(err)
                toast.error("There was a problem updating the application");
			})


	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<ApplicationPlanForm
					fields={this.state.fields} 
					entity={this.state.providers}
					save={this.save}
					selected={this.state.selected}
              	/>
        </>
	}
}

export default ApplicationPlansEdit;