import React from 'react';
import PreviewImage from '../../layout/previewImage.jsx';
import Dropzone from './dropzone.jsx'


function FileHandler(props) {
  const imageIdProps = props.imageId
  const typeProps = props.type

  return (
        <>

        {
          props.dropzone ? 

          <Dropzone 
            showImage={(acceptedFile, acceptedFileTemp) => props.showImage(acceptedFile, acceptedFileTemp, typeProps)}
            showFile={(file) => props.showFile(file)}
            fileType={props.fileType}
          />

          :
          
          props.fileType === 'images' ?

          <PreviewImage 
            preview={props.image} 
            name={props.name}
            label={props.label}
            id={imageIdProps}
            removeImage={() => props.removeImage(typeProps)}
          />

          :

          <div>hello</div>
        }

        </>
  );
}

export default FileHandler