import API from '../api.jsx'
import { toast } from 'react-toastify'

function exportPdf(applicationId) {
    API.createPdf(applicationId).then(url => {
            window.open(API.apiURL + 'storage/pdf-application/' + url, '_blank')
        })
        .catch(err => {
            console.log(err)
            toast.error("There was a problem creating the pdf");
        })
}

export default exportPdf