import React from 'react';
// import FileHandler from '../layout/file-handler/fileHandler.jsx';

class ProvidersForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
            entity: props.entity,
		}
    }

	showImage = (file, fileObj) => {
        fileObj.file = file


        this.setState(previousState => ({
			entity: {
				...previousState.entity,
				logo: fileObj
			}
        }));
    }

	removeImage = (index, type) => {
        const updatedEntity = this.state.entity

        updatedEntity.logo = null
        

        this.setState(previousState => ({
            ...previousState.entity,
            entity: updatedEntity
        }))
    }

	render() {
		return <>
			<form onSubmit={(e) => { e.preventDefault(); this.props.save(this.state.entity) }} className="custom-form">
				<div className="actions clearfix">
					<div className="buttons clearfix">
						<button className="grey" onClick={(e) => { e.preventDefault(); this.props.cancel() }}>Cancel</button>
						<button className="blue" onClick={(e) => { e.preventDefault(); this.props.save({...this.state})}}>Save page</button>
					</div>
				</div>

				<div className="block above">
					<h2>Informations</h2>
					<div className="fields clearfix">
						<div className="field">
							<label>Company name</label>
							<input type="text" value={this.state.entity.name} onChange={(e) => this.setState({ entity: { ...this.state.entity, name: e.target.value }})} />
						</div>
                        <div className="file-handler-container">
							<div className="field">
							{/* {
								this.state.entity.logo !== null  ?
                                
								<FileHandler 
									label={'Company logo'}
									image={(this.state.entity.logo !== null && this.state.entity.logo.file_url)}
									showImage={this.showImage}
									imageId={this.state.entity.logo._id !== null && this.state.entity.logo._id}
                                    removeImage={(type) => this.removeImage(this.state.entity.logo !== null && this.state.entity.logo._id, type)}
									index={'logo'}
									name={(this.state.entity.logo !== null && this.state.entity.logo.name)}
								/>

								:

								<FileHandler 
                                    showImage={this.showImage}
                                    type={'logo'}
                                    dropzone
                                />
							} */}

                            </div>
						</div>
                    </div>
                </div>
			</form>
			<div className="actions clearfix below-form">
				<button onClick={() => this.props.deleteEntity()} className="red">Delete company</button>
			</div>
		</>
	}
}

export default ProvidersForm;