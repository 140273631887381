import React from 'react';
import Field from '../field.jsx';
import FormActions from '../formActions'

class ApplicationPersonalForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			questions: props.questions,
			answers: props.answers,
			firstName: props.firstName,
			lastName: props.lastName,
			email: props.email
		}
	}

	updateOther = (value, key) => {
		this.setState({...this.state, [key]: value})
	}

	updateField = (value, key, section = null) =>{
		const answers = this.state.answers

		let newSectionAnswers = answers.map((answer) => {
			if (answer.questionId === key) {
				answer.value = value
			} 
			return answer
		})

		this.setState({...this.state, answers: newSectionAnswers })
	}

	removeNestedAnswers = arrayOfIds => {
		const answers = [...this.state.answers]
		
		answers.map(answer => {
			const answerFiltered = arrayOfIds.filter(id => id === answer.questionId)
			const answerMatched = answerFiltered.length > 0

			if(answerMatched) {
				answer.value = null
			}

			return answer
		})

		this.setState({...this.state, answers})
	}

	returnAnswerValue = id => {
		const answers = this.state.answers
		let findAnswer = answers.filter((a) => a.questionId === id)

		return findAnswer[0].value

	}

	onSubmit(e, callback) {
		e.preventDefault()
		callback()
	}

	render() {
		return (
			<form onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state))}>
				<FormActions />
				<div className="block">
					<div className="title">
						<h2>Personal Information</h2> 
					</div>
					<div className="fields clearfix wrap">
						<div className="field">
							{/* first name */}
							<label htmlFor="">First name (as in ID or passport)</label>
							<input type="text" name="firstName" onChange={(e) => this.updateOther(e.target.value, e.target.name)} value={this.state.firstName}/>
						</div>
						<div className="field">
							{/* last name */}
							<label htmlFor="">Last name (as in ID or passport)</label>
							<input type="text" name="lastName" onChange={(e) => this.updateOther(e.target.value, e.target.name)} value={this.state.lastName}/>
						</div>

						<div className="field">
							<label>Email</label>
							<input type="email" name="email" onChange={(e) => this.updateOther(e.target.value, e.target.name)} value={this.state.email}/>
						</div>

						{
							this.props.questions['personalInformation'].map((field, index) => (
								<Field id={field.id + index} returnAnswerValue={this.returnAnswerValue} field={field} updateField={this.updateField} updateOther={this.updateOther} removeNestedAnswers={this.removeNestedAnswers}/>
							))
						}
					</div>

					<div className="fields clearfix wrap address">
						{
							this.props.questions['address'].map((field, index) => (
								<Field id={field.id + index} returnAnswerValue={this.returnAnswerValue} field={field} updateField={this.updateField} updateOther={this.updateOther} removeNestedAnswers={this.removeNestedAnswers}/>
							))
						}
					</div>

					<div className="title">
						<h2>Contact</h2>
					</div>
					<div className="fields clearfix wrap">
						{
							this.props.questions['contact'].map((field, index) => (
								<Field id={field.id + index} returnAnswerValue={this.returnAnswerValue} field={field} updateField={this.updateField} updateOther={this.updateOther} removeNestedAnswers={this.removeNestedAnswers}/>
							))
						}
					</div>

					<div className="title">
						<h2>Extra Information</h2>
					</div>
					<div className="fields clearfix wrap">
						{
							this.props.questions['extraInformation'].map((field, index) => (
								<Field id={field.id + index} returnAnswerValue={this.returnAnswerValue} field={field} updateField={this.updateField} updateOther={this.updateOther} removeNestedAnswers={this.removeNestedAnswers}/>
							))
						}
					</div>
				</div>

			</form>
		)
	}
}


export default ApplicationPersonalForm;