import React from 'react';
import Loader from '../../layout/loader.jsx'
import API from '../../../services/api.jsx'
import ApplicationPersonalForm from './form.jsx';
import { toast } from 'react-toastify'
import recursiveCreateAnswersArray from '../../../services/helpers/recursiveCreateAnswersArray'

class ApplicationPersonalEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			breadcrumb: { title: 'Back to Applications', path: '/applications' },
			application: null
		}
	}

	// fill in answers array
	async addAnswerValuesToQuestions(originalAnswers, questionsObj){
		let answers = []
		for(const i in Object.keys(questionsObj)) {
			const questionsKey =  Object.keys(questionsObj)[i]

			await recursiveCreateAnswersArray(originalAnswers, questionsObj[questionsKey]).then(answersAdded => {
				answers.push([].concat(...answersAdded))
			})
		}

		return [].concat(...answers)
	}


	componentDidMount() {
		this.setState({loading: true})
		const applicationId = this.props.match.params.applicationId

		API.getQuestionsAndApplication(applicationId, 'personal').then(result => {
			this.addAnswerValuesToQuestions(result.answers, result.questions).then(answers => {
				this.setState({ 
					firstName: result.firstName,
					lastName: result.lastName,
					email: result.email,
					questions: result.questions, 
					answers: answers,
					loading: false 
				})
			})
		})
		.catch(err => {
			toast.error("There was a problem fetching the questions");
			console.log(err)
		})
	}

	async updateOtherField(fields) {
		let otherAnswers = {
			firstName: fields.firstName,
			lastName: fields.lastName,
			email: fields.email
		}

		await API.update('applications', this.props.match.params.applicationId, otherAnswers)
			.then(application => {
				console.log(application)
			})
			.catch(err => {
				console.log(err)
			})
	}

	save = (fields) => {
		this.setState({loading: true})
		const applicationId = this.props.match.params.applicationId
		
		this.updateOtherField(fields).then(() => {
			let obj = {
				answers: [JSON.parse(JSON.stringify(fields.answers))],
				personal: true
			}

			API.putAnswers(applicationId, obj).then(result => {
				console.log(result)
				this.setState({ loading: false, answers: result.answers})
				toast.success("The application has been updated");
			}).catch(err => {
				console.log(err)
				toast.error("There was a problem updating the application");
			})
		})
	}

	render() {

		if(!this.state.questions && !this.state.answers)
			return <Loader />
		else
			return <>
                <ApplicationPersonalForm
                    questions={this.state.questions} 
					firstName={this.state.firstName}
					lastName={this.state.lastName}
					email={this.state.email}
					answers={this.state.answers}
                    save={this.save}
                />
        </>
	}
}

export default ApplicationPersonalEdit;