import React from 'react';
import API from '../../../services/api.jsx'
import Head from '../../layout/head.jsx'
import Content from '../../layout/content.jsx'
import PrivateRoute from '../../layout/privateRoute'
import Loader from '../../layout/loader.jsx'
// import moment from 'moment'
import { toast } from 'react-toastify'
// import { debounce } from 'lodash'
import OptionsList from './options/list.jsx';
import PersonalList from './personal/list.jsx';
import DependantsList from './dependants/list.jsx';
import MedicalList from './medical/list.jsx';
import MedicalFollowUpsList from './medicalFollowUps/list.jsx';
import DoctorList from './doctor/list.jsx';
import EmergencyList from './emergency/list.jsx';

const routes = [
	{
		exact: true,
		path: '/providers/:providerId/plans/:planId/questions/options',
		component: OptionsList
	}, 
	{
		exact: true,
		path: '/providers/:providerId/plans/:planId/questions/personal',
		component: PersonalList
	}, 
	{
		exact: true,
		path: '/providers/:providerId/plans/:planId/questions/dependants',
		component: DependantsList
	}, 
	{
		exact: true,
		path: '/providers/:providerId/plans/:planId/questions/medical',
		component: MedicalList
	},
	{
		exact: true,
		path: '/providers/:providerId/plans/:planId/questions/medicalFollowUps',
		component: MedicalFollowUpsList
	},
	{
		exact: true,
		path: '/providers/:providerId/plans/:planId/questions/doctor',
		component: DoctorList
	},
	{
		exact: true,
		path: '/providers/:providerId/plans/:planId/questions/emergency',
		component: EmergencyList
	}
];

class PlansQuestionsView extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			breadcrumb: { title: 'Back to Plans', path: `/providers/${props.match.params.providerId}/plans/` },
			tabs: {
				links: [
					{ title: 'Personal', path: `/providers/${props.match.params.providerId}/plans/${props.match.params.planId}/questions/personal` },
					{ title: 'Dependents', path: `/providers/${props.match.params.providerId}/plans/${props.match.params.planId}/questions/dependants` },
					{ title: 'Medical', path: `/providers/${props.match.params.providerId}/plans/${props.match.params.planId}/questions/medical` },
					{ title: 'Medical Follow Ups', path: `/providers/${props.match.params.providerId}/plans/${props.match.params.planId}/questions/medicalFollowUps` },
					{ title: 'Doctor', path: `/providers/${props.match.params.providerId}/plans/${props.match.params.planId}/questions/doctor` },
					{ title: 'Emergency Contact', path: `/providers/${props.match.params.providerId}/plans/${props.match.params.planId}/questions/emergency` }
				]
			}
		}
	}

    // sync questions
    // add one individual question
	componentDidMount() {
		API.read('plans', this.props.match.params.planId).then(res => {
			this.setState({...this.state, name: res.name, loading: false})
		}).catch(err => {
			console.log(err, 'error when getting plan')
			toast.error(err.message);
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		return <>
			<Head title={this.state.name + ' questions'} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs}/>
			<Content>	
				{
					routes.map(props => (
						<PrivateRoute {...props} />
					))
				}	 			
			</Content>
		</>
	}
}

export default PlansQuestionsView;