import React, {useState, useEffect} from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import { logout } from '../../redux';
import Header from './header'
import Login from '../account/login'

import PrivateRoute from './privateRoute'

/* Client */
import OperatorList from '../operators/list.jsx'
import OperatorNew from '../operators/new.jsx'
import OperatorEdit from '../operators/edit.jsx'

/* Application */
import ApplicationList from '../applications/list.jsx'
import ApplicationNew from '../applications/plans/new.jsx'
import ApplicationView from '../applications/view.jsx'

/* Providers */
import ProvidersList from '../providers/list.jsx'
import ProviderEdit from '../providers/edit.jsx'
import ProviderNew from '../providers/new.jsx'

/* Plans */
import PlansList from '../plans/list.jsx'
import PlanEdit from '../plans/edit.jsx'
import PlanNew from '../plans/new.jsx'

/* PLansQuestions */ 
import PlansQuestionsView from '../plans/questions/view.jsx'

/* Pages */ 
import PagesList from '../pages/list.jsx'
import PageEditNew from '../pages/edit-new.jsx'

/* Questions */ 
import QuestionsList from '../questions/list.jsx';
import QuestionsNew from '../questions/new.jsx';
import QuestionsEdit from '../questions/edit.jsx';

import { connect } from 'react-redux'

function Layout(props) {
	const [showHeader, setShowHeader] = useState(false)

	useEffect(() => {
		if(props.loggedIn) {
			setShowHeader(true)
		} else {
			setShowHeader(false)
		}
	}, [props.loggedIn])

	return (
		<Router>
			{
				showHeader &&
				<Header />
			}

			<main id="main">
				<ToastContainer position="top-right" />
				<Switch>
					<Route exact path="/login" component={Login} />

					<PrivateRoute exact path="/applications" component={ApplicationList} />
					<PrivateRoute exact path="/applications/new/plans" component={ApplicationNew} />
					<PrivateRoute exact path="/applications/:applicationId/section/:section" component={ApplicationView} />

					<PrivateRoute exact path="/operators" component={OperatorList} roles={'admin'}/>
					<PrivateRoute exact path="/operators/new" component={OperatorNew} roles={'admin'}/>
					<PrivateRoute exact path="/operators/:operatorId/edit" component={OperatorEdit} roles={'admin'}/>

					<PrivateRoute exact path="/providers" component={ProvidersList} />
					<PrivateRoute exact path="/providers/new" component={ProviderNew} roles={'superAdmin'} />
					<PrivateRoute exact path="/providers/:providerId" component={ProviderEdit} roles={'superAdmin'} />
					<PrivateRoute exact path="/providers/:providerId/plans" component={PlansList} />
					<PrivateRoute exact path="/providers/:providerId/plans/:planId/edit" component={PlanEdit} roles={'superAdmin'} />
					<PrivateRoute exact path="/providers/:providerId/plans/new" component={PlanNew} roles={'superAdmin'} />

					{/* plan questions */}
					<PrivateRoute exact path="/providers/:providerId/plans/:planId/questions/:section" component={PlansQuestionsView} roles={'superAdmin'} />

					<PrivateRoute exact path="/pages" component={PagesList} roles={'admin'}/>
					<PrivateRoute exact path="/pages/:model/:pageId/edit" component={PageEditNew} roles={'admin'}/>

					<PrivateRoute exact path="/questions" component={QuestionsList} roles={'superAdmin'}/>
					<PrivateRoute exact path="/questions/new" component={QuestionsNew} roles={'superAdmin'}/>
					<PrivateRoute exact path="/questions/:questionId/edit" component={QuestionsEdit} roles={'superAdmin'}/>

					<Route
						exact
						path="/"
						render={() => {
							return (
								<Redirect to="/applications" />
							)
						}}
					/>
				</Switch>
			</main>
		</Router>
	)
}

function mapStateToProps(state) {
    return {
       loggedIn: state.loggedIn
    };
}

const mapDispatchToProps = dispatch => {
    return {
		logout: () => dispatch(logout())
    }
}

export default connect(
	mapStateToProps, mapDispatchToProps
)(Layout)