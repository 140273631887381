import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from './form.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import API from '../../services/api.jsx'
import User from '../../services/user.jsx'

class OperatorNew extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			breadcrumb: {title: 'Back to operator list', path: '/operators'},
			fields: {
				name: { label: 'Name', type: 'text', required: true },
                email: { label: 'Email', type: 'email', required: true },
                password: { label: 'Password', type: 'password', required: true },
                role: { label: 'Role', type: 'select' , 
					options: User.current().role === 'super-admin' ? [{key: 'operator', label: 'Operator'}, {key: 'admin', label: 'Admin'}] : [{key: 'operator', label: 'Operator'}]
				}
			},
			entity: {
				name: null,
				email: null,
				password: null,
				role: 'operator'
			}
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	saveEntity(fields) {
		// this.setState({ loading: true })
		API.create('users', fields).then(() => {
			toast.success('Operator has been created')
			this.props.history.push('/operators')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'New operator'} breadcrumb={this.state.breadcrumb} />
				<Content>
					<Form fields={this.state.fields} cancel={() => this.props.history.push('/operators')} save={this.saveEntity} entity={this.state.entity} />
				</Content>
			</>
	}
}

export default OperatorNew;