import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import HomepageForm from './homepage/form.jsx';
import AboutusForm from './about-us/form.jsx';
import OurplansForm from './our-plans/form.jsx';

class PageEditNew extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			breadcrumb: { title: 'Back to Pages', path: '/pages/' },
			fields: {
				label: { label: 'Page name', type: 'text', required: true },
                id: { label: 'Page ID', type: 'text', required: true },
            },
			page: null
		}

		this.keepCurrentImages = this.keepCurrentImages.bind(this)
	}

	componentDidMount() {
        const params = this.props.match.params
        API.getPage(params.model, params.pageId).then(page => this.setState({ loading: false, page: page }))
	}

	// new images that are files
	saveNewImages = (images, keys) => {
        const id = this.props.match.params.pageId
		const page = this.props.match.params.model

		let formData = new FormData()

		for(let i in keys) {
			let newImages = images[keys[i]].filter((object) => {
				return Object.keys(object).indexOf("file") > 1
			})

			newImages.map((image) => {
				return formData.append(keys[i], image.file[0])
			})
		}

		return new Promise((res, rej, next) => {
			API.putSaveNewImageFiles(id, page, formData).then((instance) => {
				if(page === 'homepage' || page === 'ourplanspage') {
					this.setState({
						...this.state,
						page: {
							...this.state.page,
							entity: {
								...this.state.page.entity,
								carouselMain: instance.carouselMain,
								companyLogos: instance.companyLogos
							}
						}

					}, () => {
						res(this.state.page.entity)
					})
				} else {
					this.setState({
						...this.state,
						page: {
							...this.state.page,
							entity: {
								...this.state.page.entity,
								carouselMain: instance.carouselMain
							}
						}

					}, () => {
						res(this.state.page.entity)
					})
				}
			}).catch((err) => {
				console.log(err)
			})
		})
    }

	async keepCurrentImages(data) {
		const id = this.props.match.params.pageId
		const page = this.props.match.params.model
		const keys = Object.keys(data)
		const newData = Object.assign(data)

		for (let i in keys) {
			let key = keys[i]

			newData[key].map(imageObj => {
				let uri = imageObj.uri.replace('storage/pages-images/', '')
				imageObj.uri = uri
				return imageObj
			})
		}

		try {
			return await API.putResaveCurrentImages(id, page, newData)
		}
		catch(err) {
			console.error('error:', err)
		}
	}
	
	// keep current images that were in the form and are in the instance and remove ones that are not there anymore
    keepCurrentImagesThenSaveNewOnes = (updatedEntity) => {
		const newImages = {...updatedEntity.temporaryImages}

		const keys = Object.keys(newImages)

		let data = {}

		for(let i in keys) {
			let currentImages = newImages[keys[i]].filter((object) => {
				return Object.keys(object).indexOf("file") < 1
			})
			data[keys[i]] = currentImages
		}

		this.keepCurrentImages(data).then(result => {
			this.setState({
				...this.state,
				page: {
					entity: updatedEntity.entity,
					temporaryImages: newImages
				},
			}, () => {
				this.saveNewImages(newImages, keys).then(entity => {
					this.saveEntity(entity)
				}).catch(err => {
					console.err('error: ', err)
				})
			})
		})
    }

	saveEntity = updatedEntity => {
		API.update(this.props.match.params.model, this.props.match.params.pageId, updatedEntity).then((entity) => {
			toast.success('Page has been updated')
			this.props.history.push('/pages/')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		const params = this.props.match.params
		const titleText = (params.model === 'homepage' && 'Homepage') || (params.model === 'aboutpage' && 'About us') || (params.model === 'ourplanspage' && 'Our plans')

		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={titleText} tabs={this.state.tabs} />
				<Content>
					{
						params.model === 'homepage' &&
						<HomepageForm 
							fields={this.state.fields} 
							entity={this.state.page} 
							cancel={() => this.props.history.push('/pages')} 
							save={this.keepCurrentImagesThenSaveNewOnes} 
						/>
					 }
					 {
						 params.model ==='aboutpage' && 

						 <AboutusForm 
							fields={this.state.fields} 
							entity={this.state.page} 
							cancel={() => this.props.history.push('/pages')} 
							save={this.keepCurrentImagesThenSaveNewOnes} 
						 />
					 }

					 {
						 params.model === 'ourplanspage' &&

						 <OurplansForm
							fields={this.state.fields} 
							entity={this.state.page} 
							cancel={() => this.props.history.push('/pages')} 
							save={this.keepCurrentImagesThenSaveNewOnes} 
						 />
					 }
                </Content>
			</>
	}
}

export default PageEditNew;