import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import API from '../../../services/api.jsx'


function Dropzone(props) {
  const [files, setFiles] = useState([]);
  const [acceptableType, setAcceptableType] = useState('')
  const fileTypeProps = props.fileType

  const {getRootProps, getInputProps} = useDropzone({
    accept: acceptableType,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        ...acceptedFiles,
        preview: URL.createObjectURL(file)
      })));
      if(fileTypeProps === 'images') {
        API.postTempImage(acceptedFiles[0]).then(acceptedFileTemp => {
          acceptedFileTemp.temp = true
          props.showImage(acceptedFiles[0], acceptedFileTemp)
        })
      } else {
        props.showFile(acceptedFiles[0])
      }
    }
  });

  useEffect(() => {
    if(fileTypeProps === 'images') {
      setAcceptableType('image/*')
    } else {
      setAcceptableType('application/pdf')
    }
  },[fileTypeProps])


  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    if(files.length) {
      files.forEach(file => {
        URL.revokeObjectURL(file.preview)
      })
    }
  }, [files]);

  return (
        <>
          <div key={props.key}{...getRootProps({className: 'dropzone image-wrap'})}>
              <input {...getInputProps()} disabled={props.disabled}/>
              <div className="image-icon-wrap icon-downloading">
                  <p>Drag 'n' drop a new file</p>
              </div>
          </div>
        </>
  );
}

export default Dropzone