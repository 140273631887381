import React, {useState, useEffect} from 'react';
import SelectNationality from '../../services/helpers/nationality';


function Field(props) {
    let [showFollowUpQuestions, setShowFollowUpQuestions] = useState(props.questions ? true : false)
    let [questions, setQuestions] = useState(props.isFollowUp ? props.questions : [])
    let [key, setKey] = useState(props.id + 1)
    const field = props.field

    useEffect(() => {
        setKey(props.id + 1)
        const question = props.field
        let hasOptions = question.answerType === 'radio' || question.answerType === 'select' || question.answerType === 'nationality' || question.answerType === 'checkbox';

        if(hasOptions) {
            const options = question.options 

            for(const i in options) {
                const option = options[i]
                // if the answer triggers a follow up and the answer value is th same as the option value
                if(option.triggersFollowUp && props.returnAnswerValue(question.id) === option.value) {
                    // show follow up at bottom
                    setShowFollowUpQuestions(true)
                    setQuestions(option.followUps.questions)
                    // because follow up has been found, no need to continue looping
                    break;
                } else {
                    // hide follow ups at the bottom if no follow ups have been selected
                    setShowFollowUpQuestions(false)
                    setQuestions(false)
                }
            } 
        }
    }, [props])

    const returnQuestionIds = (questions) => {
        return new Promise(async(res, rej) => {
            let questionIds = []

            for(const i in questions) {
                const question = questions[i]

                if(question === undefined) {
                    return
                }
                // if options, set options
                let hasOptions = question.answerType === 'radio' || question.answerType === 'select' || question.answerType === 'nationality' || question.answerType === 'checkbox';
                // function is always passed a question so return its id
                questionIds.push(question.id)

                if(hasOptions) {
                    for(const j in questions.options) {
                        const option = questions.options[j]
                        const noFollowUps = (option === null || option === undefined) || option.followUps.options === null || option.followUps.questions === [] || option.followUps.questions[0] === null || option.followUps.questions.length === 0

                        if (noFollowUps) {
                        } else {
                            // if there are follow up questions then gather those question ids
                            await returnQuestionIds(option.followUps.questions).then(questionIdsArray => {
                                questionIds.push([].concat(...questionIdsArray))
                            })
                        } 
                    }
                }
            }
            res([].concat(...questionIds))
        })
    }

    const showFollowUp = async (value, id, options) => {
        props.updateField(value, id)
        
        const questionIds = []
        for(const j in options) {
            const option = options[j]

            // if selected option doesnt equal current answer
            if(option.value !== props.returnAnswerValue(id)) {
                // if the old answer triggers a follow up question
                if(option.triggersFollowUp) {
                    // get those question ids and make those answers null
                    await returnQuestionIds(option.followUps.questions).then(questionIdsArray => {
                        questionIds.push([].concat(...questionIdsArray))
                        // remove any nested answers from follow ups
                        props.removeNestedAnswers([].concat(...questionIds))
                    })
                }
            } else {
                // selected and has follow ups
                if(option.triggersFollowUp) {
                    // render follow up (see the bottom of the component)
                    setQuestions(option.followUps.questions)
                    setShowFollowUpQuestions(true)
                }
                // selected but has no follow ups
                else {
                    // new selected does not have follow ups so hide them
                    setQuestions([])
                    setShowFollowUpQuestions(false)
                }
            }
        }
    }

    return (
        <>
            {
                <>
                {
                    field.answerType === 'address' || field.answerType === 'groupedQuestions' ?
                    <>
                    <div className="grouped-fields">
                        <h3>{field.label.english}</h3>
                        <div className="fields clearfix wrap">
                            {
                                field.answerType === 'address' &&
                                    field.addressQuestions.map(addressField => (
                                        <Field key={field.id} field={addressField} updateField={props.updateField} returnAnswerValue={props.returnAnswerValue} removeNestedAnswers={props.removeNestedAnswers} />
                                    )) 
                            }
                            {
                                field.answerType === 'groupedQuestions' &&
                                    field.groupQuestions.map(groupField => (
                                        <Field key={field.id} field={groupField} updateField={props.updateField} returnAnswerValue={props.returnAnswerValue} removeNestedAnswers={props.removeNestedAnswers}/>
                                    )) 
                            }
                        </div>
                    </div>
                    </>
                    :
                    <div className={"field"  + (field.answerType === 'textarea' ? ' textarea' : '') + (field.answerType === 'checkboxGroup' ? ' checkbox-group' : '')} key={key + field.id}> 

                        {!(props.initialRender && props.medical) && field.answerType !== 'checkboxGroup' && <label>{ field.label.english }</label>}
                        {field.answerType === 'checkboxGroup' && <h3>{ field.label.english }</h3>}
                        {
                            (field.answerType === 'string' || field.answerType === 'firstName' || field.answerType === 'lastName' || field.answerType === 'fullName') && <input key={field.id + field.label.english} type="text" onChange={(e) => props.updateField(e.target.value, field.id)} name={field.id} value={props.returnAnswerValue(field.id)} required={field.required} />
                        }
                        {
                            field.answerType === 'date' && <input key={field.id + field.label.english} type="date" onChange={(e) => props.updateField(e.target.value, field.id)} name={field.id} value={props.returnAnswerValue(field.id)} required={field.required} />
                        }
                        {
                            field.answerType === 'number' && <input key={field.id + field.label.english} type="number" onChange={(e) => props.updateField(e.target.value, field.id)} name={field.id} value={props.returnAnswerValue(field.id)} required={field.required} />
                        }
                        {
                            field.answerType === 'email' && <input key={field.id + field.label.english} type="email" onChange={(e) => props.updateField(e.target.value, field.id)} name={field.id} value={props.returnAnswerValue(field.id)} required={field.required} />
                        }
                        {
                            field.answerType === 'textarea' && <textarea key={field.id + field.label.english} onChange={(e) => props.updateField(e.target.value, field.id)} name={field.id} value={props.returnAnswerValue(field.id)} required={field.required}></textarea>
                        }
                        {
                            field.answerType === 'select' && 
                                <>
                                <select key={field.id + field.label.english} onChange={(e) => {showFollowUp(e.target.value, field.id, field.options)}} 
                                name={field.id} value={props.returnAnswerValue(field.id)} defaultValue="placeholder" required={field.required} >
                                    <option value="placeholder" disabled={true}>Please select an option..</option>
                                    {
                                        field.options.map((option, index) => 
                                            <>
                                            <option key={props.id + field.id + index} value={option.value}>{option.label.english}</option>
                                            </>
                                        )
                                    }
                                </select>
                                </>
                                
                        } 
                        {
                            field.answerType === 'nationality' && <SelectNationality value={props.returnAnswerValue(field.id)} updateField={props.updateField} name={field.id} field required={field.required} />
                        }
                        {
                            field.answerType === 'radio' && 
                                <div key={field.id + field.label.english} className="radio">
                                    {
                                        field.options.map((option, index) => 
                                        <>
                                            <input key={field.id + field.label.english} type="radio" name={field.id + '-' + index} value={option.value} checked={option.value === props.returnAnswerValue(field.id) ? true : false} onChange={(e) => showFollowUp(e.target.value, field.id, field.options)} />
                                            <span>{option.label.english}</span>
                                        </>
                                        ) 
                                    }
                                </div>									
                        }
                        {
                            field.answerType === 'checkboxGroup' &&
                                <div key={field.id + field.label.english} className="checkboxes">
                                    {
                                        field.groupQuestions.map((subField, index) => 
                                        <>
                                            <div className="checkbox">
                                                <label onClick={(e) => e.stopPropagation()}><input type="checkbox" checked={props.returnAnswerValue(subField.id)} onChange={() => props.updateField(!props.returnAnswerValue(subField.id), subField.id)} /><span></span>{subField.label.english}</label>
                                            </div>
                                        </>
                                        ) 
                                    }
                                </div>									

                        }
                        {
                            field.answerType === 'checkbox' &&
                                <div key={field.id + field.label.english} className="checkboxes">
                                    <div className="checkbox">
                                        <label onClick={(e) => e.stopPropagation()}><input type="checkbox" checked={props.returnAnswerValue(field.id)} onChange={() => props.updateField(!props.returnAnswerValue(field.id), field.id)} /><span></span></label>
                                    </div>
                                </div>	
                        }
                    </div>
                }
                {
                    showFollowUpQuestions && 
                    questions.map((field, index) => (
                        <Field isFollowUp id={index + props.id} field={field} updateField={props.updateField} returnAnswerValue={props.returnAnswerValue} removeNestedAnswers={props.removeNestedAnswers} />
                    ))
                }
                </>
            }
        </>
    )
}


export default Field;