import React from 'react';
import Field from '../field.jsx';
import FormActions from '../formActions'

class Form extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			questions: props.questions,
			answers: props.answers
		}

	}

	updateDoctorsDetails = (key, value) => {
		this.setState({...this.state, doctorsDetails: {[key]: value }})
	}

	updateField = (value, key, section = null) =>{
		const answers = this.state.answers

		let newSectionAnswers = answers.map((answer) => {
			if (answer.questionId === key) {
				answer.value = value
			} 
			return answer
		})

		this.setState({...this.state, answers: newSectionAnswers })
	}

	removeNestedAnswers = arrayOfIds => {
		const answers = [...this.state.answers]
		
		answers.map(answer => {
			const answerFiltered = arrayOfIds.filter(id => id === answer.questionId)
			const answerMatched = answerFiltered.length > 0

			if(answerMatched) {
				answer.value = null
			}

			return answer
		})

		this.setState({...this.state, answers})
	}

	returnAnswerValue = id => {
		const answers = this.state.answers
		let findAnswer = answers.filter((a) => a.questionId === id)

		return findAnswer[0].value
	}

	onSubmit(e, callback) {
		e.preventDefault()
		callback()
	}

	render() {
		return (
			<form onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state))}>
				<FormActions />
				<div className="block">
					<h2>Doctor</h2>
					<div className="fields clearfix wrap">
						{
							this.props.questions.length > 0 &&
								this.props.questions.map((field, questionIndex) => (
										<Field id={questionIndex + this.state.noOfDependants} returnAnswerValue={this.returnAnswerValue} field={field} updateField={this.updateField} updateOther={this.updateOther} removeNestedAnswers={this.removeNestedAnswers} initialRender/> 
								))
						}
					</div>
				</div>
			</form>
		)
	}
}


export default Form;