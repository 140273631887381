import React from 'react';
import FormActions from '../formActions'
import Field from '../field.jsx'

class ApplicationMedicalForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			questions: props.questions,
			answers: props.answers,
			followUpAnswers: {},
			nameAnswers: props.nameAnswers
		}
	}

	componentDidMount() {
		const questionsArrays = this.props.questions
		const followUpAnswers = this.state.followUpAnswers
		const answers = this.props.answers
		const followUpsFromBE = this.props.followUpAnswers
		if(this.props.hasMedicalFollowUps) {
			// create followUpAnswer keys
			questionsArrays.forEach((questionsArray, questionsIndex) => {
				questionsArray.forEach((question, index) => {
					followUpAnswers[questionsIndex + '-' + index] = ''
				})
			})
	
			let count = 0
			// where user has typed yes, add their follow up answer to followUpAnswers
			questionsArrays.forEach((questions, questionIndex) => {
				for(const i in questions) {
					const question = questions[i]
	
					for(const j in answers) {
						const answer = answers[j]
						const answeredYes = answer.value === 'yes' && answer.questionId === question.id
						// if user answered yes
						if(answeredYes) {
							// fill in their follow up answer
							followUpAnswers[questionIndex + '-' + i] = followUpsFromBE[count] 
							count += 1
						}
					}
				}
			})
	
	
			this.setState({...this.state, followUpAnswers})
		}
	}

	// find key in follow up answers and update value
	updateFollowUpAnswers = (value, questionIndex, index) => {
		const followUpAnswers = {...this.state.followUpAnswers}
		followUpAnswers[questionIndex + "-" + index] = value
		this.setState({followUpAnswers})
	}

	// update yes or no
	updateField = (value, key, questionIndex, index) =>{
		const answers = this.state.answers
		const followUpAnswers = this.state.followUpAnswers

		let newSectionAnswers = answers.map((answer) => {
			if (answer.questionId === key) {
				answer.value = value
			} 
			return answer
		})

		// remove follow up answer
		followUpAnswers[questionIndex + "-" + index] = ''

		this.setState({answers: newSectionAnswers, followUpAnswers: followUpAnswers})
	}

	returnAnswerValue = id => {
		const answers = this.state.answers
		let findAnswer = answers.filter((a) => a.questionId === id)
		if(findAnswer[0] !== undefined) {
			return findAnswer[0].value
		} else {
			return ''
		}
	}

	removeNestedAnswers = arrayOfIds => {
		const answers = [...this.state.answers]
		
		answers.map(answer => {
			const answerFiltered = arrayOfIds.filter(id => id === answer.questionId)
			const answerMatched = answerFiltered.length > 0
			if(answerMatched) {
				answer.value = null
			}
			return answer
		})

		this.setState({...this.state, answers})
	}

	onSubmit(e, callback) {
		e.preventDefault()
		callback()
	}

	render() {
		return (
			<form onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state))}>
				<FormActions />
				<div className="block">
					<ol>
					{
						this.props.questions.map((questionArray, questionIndex) => (
							<>
							<li>
							{
								questionArray.map((question, index) => (

								<>
									<div className="field medical">
										{
											index === 0 && 
											<p className="question">{question.label.english}</p> 
										}
										{
											this.props.medicalQuestionsForMultipleInsured &&
											<label style={{color: !this.props.nameAnswers[index] && 'red', fontWeight: !this.props.nameAnswers[index] && '600'}}>{this.props.nameAnswers[index] ? this.props.nameAnswers[index].firstName : 'TOO MANY QUESTIONS - REMOVE THIS QUESTION: ' + question.value}  {this.props.nameAnswers[index] && this.props.nameAnswers[index].lastName}</label>
										}
										<Field id={question.id + index} returnAnswerValue={(id) => this.returnAnswerValue(id)} field={question} updateField={(value, key) => this.updateField(value, key, questionIndex, index)} updateOther={(value, key) => this.updateOther(value, key)} removeNestedAnswers={this.removeNestedAnswers} initialRender medical/>
										{
											this.returnAnswerValue(question.id) === 'yes' && this.props.hasMedicalFollowUps &&
											<>
											<div className="sub-field-medical-follow">
												<label className="label">{this.props.followUpQuestionTemplate.english}</label>
												<textarea required value={this.state.followUpAnswers[questionIndex + "-" + index]} name={'follow-up-' + index} onChange={(e) => this.updateFollowUpAnswers(e.target.value, questionIndex, index)}></textarea>
											</div>
											</>
										}
									</div>
								</>
								))
							}
							</li>
							</>
						))
					}
					</ol>
				</div>

			</form>
		)
	}
}

export default ApplicationMedicalForm;