import React from 'react';
import Loader from '../../layout/loader.jsx'
import API from '../../../services/api.jsx'
import Form from './form.jsx';
import { toast } from 'react-toastify'
import recursiveCreateAnswersArray from '../../../services/helpers/recursiveCreateAnswersArray'

class ApplicationDoctorEdit extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			breadcrumb: { title: 'Back to Applications', path: '/applications' },
			application: null
		}
	}

    parseQuestions = questions => {
        let newQuestionsArray = []
        questions.map(question => {
            return newQuestionsArray.push(question.question)
        })

        return newQuestionsArray
    }

	componentDidMount() {
		this.setState({loading: true})
		const applicationId = this.props.match.params.applicationId
		
		API.read('applications', applicationId).then(application => {
			API.getQuestionsAndApplication(applicationId, 'doctor').then(result => {
				recursiveCreateAnswersArray(result.answers, result.questions).then(questions => {
					const updatedAnswers = [].concat(...questions)
					const updatedQuestions = this.parseQuestions(result.questions)
					this.setState({questions: updatedQuestions, answers: updatedAnswers})
				})
			})
			.catch(err => {
				this.setState({ loading: false })
				toast.error("There was a problem fetching the application");
			})

		})
		.catch(err => {
			this.setState({ loading: false })
			toast.error("There was a problem fetching the application");
		})
	}

	save = (fields) => {
		this.setState({loading: true})
		const applicationId = this.props.match.params.applicationId
		const answers = JSON.parse(JSON.stringify(fields.answers))

		let obj = {
			answers: [answers],
			application: true
		}

		API.putAnswers(applicationId, obj).then(result => {
			this.setState({ loading: false, answers: result.answers})
			toast.success("The application has been updated");
		}).catch(err => {
			console.log(err)
			toast.error("There was a problem updating the application");
		})
	}

	render() {
		if(!this.state.answers)
			return <Loader />
		else
			return <>
                <Form
					answers={this.state.answers}
					questions={this.state.questions}
					save={this.save}
                />
        </>
	}
}

export default ApplicationDoctorEdit;