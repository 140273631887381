import React from 'react';
import API from '../../services/api.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import Loader from '../layout/loader.jsx'
// import moment from 'moment'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'

class OperatorsList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			filters: {
				name: ''
			},
			operators: {
				columns: [{
					label: 'Operator',
					key: 'name'
				}, {
					label: 'Role',
					key: 'role'
				}, {
					action: 'edit and delete',
					onClickDelete: (id, key) => {
						var r = window.confirm("Are you sure you want to delete this operator?");
						if (r === false) return

						this.setState({ loading: true })
						API.delete('users', id).then(() => {
							toast.success('Operator was successfully deleted')
							this.loadEntities()
						}).catch(() => {
							toast.success('An unexpected error has occured')
							this.setState({ loading: false })
						})
					},
					onClickEdit: (entityId) => {
						this.props.history.push('/operators/' + entityId + '/edit')
					}
				}],
				entities: [],
				filters: [{
					label: 'Search for an operator',
					onChange: debounce(value => {
						if(value !== this.state.filters.name)
							this.setState({ filters: { ...this.state.filters, name: value ? '/' + value + '/i' : '' }}, () => {
								this.loadEntities()
							})
					}, 500)
				}],
				actions: [{
					classList: 'blue add',
					label: 'New operator',
					onClick: () => {
						this.props.history.push('/operators/new')
					}
				}],
				selectedItems: [],
				multiSelect: false
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
		API.getOperators(this.state.filters).then(entities => this.setState({ ...this.state, loading: false, operators: {...this.state.operators, entities: entities} }))
	}

	viewOperator(id) {
		this.props.history.push('/operators/' + id + '/ed');
	}

	render() {
		if(this.state.loading)
			return <Loader />
		return <>
			<Head title={'Operators'} />
			<Content>
				<Table settings={this.state.operators} />				
			</Content>
		</>
	}
}

export default OperatorsList;
