import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import PlansForm from './form.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'

class PlanEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			breadcrumb: { title: 'View plans', path: '/providers/' + props.match.params.providerId + '/plans/' },
            fields: {
				date: {
					options: [
						{key: 'YYYY/MM/DD', label: 'YYYY/MM/DD'},
						{key: 'DD/MM/YYYY', label: 'DD/MM/YYYY'},
						{key: 'MM/DD/YYYY', label: 'MM/DD/YYYY'},
						{key: 'YY/MM/DD', label: 'YY/MM/DD'},
						{key: 'DD/MM/YY', label: 'DD/MM/YY'},
						{key: 'MM/DD/YY', label: 'MM/DD/YY'},
						{key: 'DD MM YYYY', label: 'DD MM YYYY'},
					],
				},
                language: {
                    options: [
                        {key: 'englishAndChinese', label: 'English & Chinese'},
                        {key: 'english', label: 'English'},
                        {key: 'chinese', label: 'Chinese'},
                    ]
                }
			}, 
            plan: {
                name: null,
                pdfTemplate: {
                    fieldname: null,
                    name: null,
                    size: null,
                    type: null,
                    uploadedAt: null
                },
                tempFile: {},
                answerFormat: {
                    hasMedicalFollowUps: true,
                    uppercase: false,
                    language: 'englshAndChinese',
                    isInsuredOneDependantOne: false,
                    medicalQuestionsForMultipleInsured: false
                }
            }
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	saveEntity(fields) {
		let formData = new FormData()

        if(fields.name !== '' && fields.tempFile !== undefined) {
            let data ={
                provider: this.props.match.params.providerId,
                name: fields.name,
                answerFormat: {
                    date: fields.answerFormat.date,
                    uppercase: fields.answerFormat.uppercase,
                    language: fields.answerFormat.language,
                    isInsuredOneDependantOne: fields.answerFormat.isInsuredOneDependantOne,
                    medicalQuestionsForMultipleInsured: fields.answerFormat.medicalQuestionsForMultipleInsured
                },
                hasMedicalFollowUps: fields.hasMedicalFollowUps
            }
    
            formData.append(fields.tempFile.name, fields.tempFile)
            
            this.setState({ loading: true })
    
            API.create('plans', data).then((plan) => {
                API.putsPdfTemplate(plan.id, formData).then(() => {
                        toast.success('Plan has been created')
                        this.props.history.push('/providers/' + this.props.match.params.providerId + '/plans')
        
                    }).catch(() => {
                        toast.error('An unexpected error occured when updating the pdf template, please try again')
                        this.setState({ loading: false })
                    })
                }).catch(() => {
                    toast.error('An unexpected error occured when creating a plan, please try again')
                    this.setState({ loading: false })
                })
        } else {
            toast.error('Do not leave any empty fields')
        }
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'New plan'} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs} />
				<Content>
					<PlansForm
                        entity={this.state.plan} 
                        fields={this.state.fields}
                        cancel={() => this.props.history.push('/providers/' + this.props.match.params.providerId +'/plans/')} 
                        save={this.saveEntity} 
                    />
				</Content>
			</>
	}
}

export default PlanEdit;