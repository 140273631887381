  
import React from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'

import User from '../../services/user'

function PrivateRoute({ component: Component, roles, ...rest }) {
    let history = useHistory()
    return (
        <Route {...rest} render={props => {
            const user = User.current()
            if (!user || user.logout) {
                history.push('/login')
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }

            // check if route is restricted by role on page
            if(roles && User.hasRole(roles)) {
                return <Component {...props} />
            }

            // check if route is restricted by role
            if (roles && roles.indexOf(user.role) === -1) {
                // role not authorized so redirect to home page
                return <Redirect to={{ pathname: '/'}} />
            }

            // authorized so return component
            return <Component {...props} />
        }} />
    );
}

export default PrivateRoute