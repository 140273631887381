import React from 'react';


class QuestionsForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = props.entity
	}

    updateLabel = (value, key) => {
        this.setState(prevState => ({...prevState, label: {...prevState.label, [key]: value}}))
    }

	updateField = (e, key) => { 
        this.setState(prevState => ({...prevState, [key]: e.target.value }))
	}

    updateFollowOption = (e, index) => {
        const value = e.target.value
        const state = JSON.parse(JSON.stringify(this.state))

        state.options[index].followUps['type'] = value

        if(value === 'medical')
            state.options[index].followUps.questions = ['']

        this.setState({...state})
    }

    updateFollowUpQuestion = (e, index, questionIndex) => {
        const state = JSON.parse(JSON.stringify(this.state))
        state.options[index].followUps.questions[questionIndex] = e.target.value

        this.setState({...state})
    }

    addGroupedQuestion = () => {
        const state = JSON.parse(JSON.stringify(this.state))
        state.groupQuestions.push(null) 

        this.setState({...state})
    }

    updateGroupedQuestion = (e, index) => {
        const state = JSON.parse(JSON.stringify(this.state))
        state.groupQuestions[index] = e.target.value

        this.setState({...state})
    }

    removeGroupedQuestion = (index) => {
        const state = JSON.parse(JSON.stringify(this.state))
        state.groupQuestions.splice(index, 1)

        this.setState({...state})
    }

    addAddressQuestion = () => {
        const state = JSON.parse(JSON.stringify(this.state))
        state.addressQuestions.push(null) 

        this.setState({...state})
    }

    updateAddressQuestion = (e, index) => {
        const state = JSON.parse(JSON.stringify(this.state))
        state.addressQuestions[index] = e.target.value

        this.setState({...state})
    }

    removeAddressQuestion = (index) => {
        const state = JSON.parse(JSON.stringify(this.state))
        state.addressQuestions.splice(index, 1)

        this.setState({...state})
    }

    addFollowUpQuestion = index => {
        const state = JSON.parse(JSON.stringify(this.state))
        state.options[index].followUps.questions = [...state.options[index].followUps.questions, undefined]

        this.setState({...state})
    }

    removeFollowUpQuestion = (index, questionIndex) => {
        const state = JSON.parse(JSON.stringify(this.state))
        state.options[index].followUps.questions.splice(questionIndex, 1)

        this.setState({...state})
    }

    updateOption = (e, index) => {
        const key = e.target.name
        const value = e.target.value
        const options = this.state.options

        const newOptions = options.map((option, optionIndex) => {
            if(index === optionIndex) {
                if(key.match(/label/)) {
                    if(key.match(/english/)) {
                        option.label.english = value
                    } else {
                        option.label.chinese = value
                    }
                } else {
                    if(key.match(/triggersFollowUp/)) {
                        const valueTorF = value === 'true'
                        option['triggersFollowUp'] = valueTorF
                    } else
                        option[key] = value
                }
                return option
            } 
            return option
        })

        this.setState(prevState => ({...prevState, options: newOptions}))
    }

    addOption = () => {
        const options = this.state.options

        const newOption = {
            value: '',
            label: {
                english: '',
                chinese: ''
            },
            triggersFollowUp: false,
            followUps: {
                type: '',
                questions: [undefined]
            },
        }

        const newOptions = [...options, newOption]

        this.setState(prevState => ({...prevState, options: newOptions}))
    }

    removeOption = index => {
        let options = this.state.options

        options.splice(index, 1)

        this.setState(prevState => ({...prevState, options}))
    }

	onCancel(e, callback) {
		e.preventDefault()
		callback()
	}

	onSubmit(e, callback) {
		e.preventDefault()
		callback()
	}

	render() {
		return (
			<form className="questions" onSubmit={(e) => this.onSubmit(e, () => this.props.save({...this.state}))}>
				<div className="actions clearfix">
					<div className="buttons clearfix">
						<div className="button grey" onClick={(e) => this.onCancel(e, () => this.props.cancel())}>Cancel</div>
						<button className="blue">Save</button>
					</div>
				</div>

				<div className="block">
                    <div className="fields clearfix">
                        <h2>Value/ID</h2>
                        <div className="field">
                            <label>Value</label>
                            <input type="text" required={true} onChange={(e) => this.updateField(e, 'value')} value={this.state.value}/>
                        </div>
                    </div>
					<div className="fields clearfix">
                        <h2>Labels</h2>
                        <div className="field">
                            <label>Label EN</label>
                            <input name="english" type="text"  required={true} onChange={(e) => this.updateLabel(e.target.value, e.target.name)} value={this.state.label.english}/>
                        </div>
                        <div className="field">
                            <label>Label CN</label>
                            <input name="chinese" type="text"  required={true} onChange={(e) => this.updateLabel(e.target.value, e.target.name)} value={this.state.label.chinese}/>
                        </div>
					</div>

                    <div className="fields clearfix">
                        <h2>Answer Required</h2>

                        <div className="field">
                            <div className="radio">
                                <input id="required-true" name="required" type="radio" value={true} onChange={(e) => this.updateField(e, e.target.name)} checked={this.state.required === "true" || this.state.required === true}/>
                                <span>Yes</span>
                                <input id="required-false" name="required" type="radio" value={false} onChange={(e) => this.updateField(e, e.target.name)} required checked={this.state.required === "false" ||  this.state.required === false}/>
                                <span>No</span>
                            </div>
                        </div>
                    </div> 

                    <div className="fields clearfix">
                        <h2>Show only on backoffice?</h2>

                        <div className="field">
                            <div className="radio">
                                <input id="showOnlyOnBackoffice-true" name="showOnlyOnBackoffice" type="radio" value={true} onChange={(e) => this.updateField(e, e.target.name)} checked={this.state.showOnlyOnBackoffice === "true" || this.state.showOnlyOnBackoffice === true}/>
                                <span>Yes</span>
                                <input id="showOnlyOnBackoffice-false" name="showOnlyOnBackoffice" type="radio" value={false} onChange={(e) => this.updateField(e, e.target.name)} required checked={this.state.showOnlyOnBackoffice === "false" ||  this.state.showOnlyOnBackoffice === false}/>
                                <span>No</span>
                            </div>
                        </div>
                    </div> 

                    <div className="fields clearfix">
                        <h2>Which language will this question be for?</h2>
                        <div className="field">
                            <select name="language" id="" value={this.state.language} onChange={(e) => this.updateField(e, e.target.name)} required={true}>
                                {this.props.fields.language.options.map(option => {
                                    return <option value={option.key}>{option.label}</option>
                                })}
                            </select>
                        </div>
					</div> 

                    <div className="fields clearfix">
                        <h2>Question type</h2>
                        <div className="field">
                            <select name="answerType" id="" value={this.state.answerType} onChange={(e) => this.updateField(e, e.target.name)} required={true}>
                                {this.props.fields.answerType.options.map(option => {
                                    return <option value={option.key}>{option.label}</option>
                                })}
                            </select>
                        </div>
					</div> 
                    {
                        this.state.answerType === 'date' &&
                        <div className="fields clearfix">
                            <h3>Date format</h3>
                            <div className="field">
                                <select name="dateType" id="" value={this.state.dateType} onChange={(e) => this.updateField(e, e.target.name)} required={true}>
                                {this.props.fields.date.options.map(option => (
                                    <option value={option.key}>{option.label}</option>
                                ))}
                                </select>
                            </div>
                        </div>
                    }

                    {
                        this.state.answerType === 'address' &&
                        <>
                        <div className="fields clearfix">
                            <h2>Address Field</h2>
                        </div>

                        <div style={{clear: 'both'}} className="options-container">
                            <h3>Questions</h3>
                            {this.state.addressQuestions.map((field, index) => (
                                <>
                                <div className="fields clearfix">
                                    <div className="field">
                                        <select name="question" value={field} onChange={(e) => this.updateAddressQuestion(e, index)}  defaultValue="placeholder" required={true}>
                                            <option value="placeholder" disabled={true}>Please choose a question</option>
                                            {this.props.questions.map(questionEntity => (
                                                <option value={questionEntity.id}>{questionEntity.value}</option>
                                            ))}
                                        </select>

                                        <div className="buttons">
                                            {index === this.state.addressQuestions.length - 1 && <div onClick={() => {this.addAddressQuestion()}} className="add blue button"></div>}
                                            {index !== 0 && <div onClick={() => {this.removeAddressQuestion(index)}} className="red button">remove</div>}
                                        </div>
                                    </div>
                                </div>
                                </>
                            ))}
                        </div>
                        </>
                    }
                    {
                        (this.state.answerType === 'groupedQuestions' || this.state.answerType === 'checkboxGroup') &&
                        <>
                        <div className="fields clearfix">
                            <h2>{this.state.answerType === 'groupedQuestions' ? 'Group Questions Field' : 'Checkbox Group Field'}</h2>
                        </div>

                        <div style={{clear: 'both'}} className="options-container">
                            <h3>Questions</h3>
                            {this.state.groupQuestions.map((field, index) => (
                                <>
                                <div className="fields clearfix">
                                    <div className="field">
                                        <select name="question" value={field} onChange={(e) => this.updateGroupedQuestion(e, index)}  defaultValue="placeholder" required={true}>
                                            <option value="placeholder" disabled={true}>Please choose a question</option>
                                            {
                                                this.state.answerType === 'checkboxGroup' 
                                                ?
                                                this.props.questions.filter(q => q.answerType === 'checkbox').map(questionEntity => (
                                                    <option value={questionEntity.id}>{questionEntity.value}</option>
                                                ))
                                                :
                                                this.props.questions.map(questionEntity => (
                                                    <option value={questionEntity.id}>{questionEntity.value}</option>
                                                ))
                                            }
                                        </select>

                                        <div className="buttons">
                                            {index === this.state.groupQuestions.length - 1 && <div onClick={() => {this.addGroupedQuestion()}} className="add blue button"></div>}
                                            {index !== 0 && <div onClick={() => {this.removeGroupedQuestion(index)}} className="red button">remove</div>}
                                        </div>
                                    </div>
                                </div>
                                </>
                            ))}
                        </div>
                        </>
                    }
                    {
                        (this.state.answerType === 'select' || this.state.answerType === 'radio') &&
                        <>
                        <div className="fields clearfix">
                            <h2>Options</h2>
                        </div>

                        {this.state.options.map((field, index) => (
                        <>
                        <div style={{clear: 'both'}} className="options-container">
                            <div className="fields clearfix">
                                <h3>Value/ID</h3>
                                <div className="field">
                                    <input key={index} value={field.value} name={'value'} type="text" onChange={(e) => this.updateOption(e, index)}  required={true}/>
                                </div>
                            </div>

                            <div className="fields clearfix">
                                <h3>Labels</h3>
                                <div className="field">
                                    <label>Label EN</label>
                                    <input key={index} value={field.label.english} name={"label-english"} type="text" onChange={(e) => this.updateOption(e, index)}  required={true} />
                                </div>
                                <div className="field">
                                    <label>Label CN</label>
                                    <input key={index} value={field.label.chinese} name={"label-chinese"} type="text" onChange={(e) => this.updateOption(e, index)}  required={true} />
                                </div>
                            </div>

                            <div className="fields clearfix">
                                <h3>Has follow up question?</h3>

                                <div className="field">
                                    <div className="radio">
                                        <input id="triggersFollowUp-true" name={"triggersFollowUp-" + index} type="radio" value={true} onChange={(e) => this.updateOption(e, index)} checked={field.triggersFollowUp === "true" || field.triggersFollowUp === true}/>
                                        <span>Yes</span>
                                        <input id="triggersFollowUp-false" name={"triggersFollowUp-" + index} type="radio" value={false} onChange={(e) => this.updateOption(e, index)} required checked={field.triggersFollowUp === "false" || field.triggersFollowUp === false}/>
                                        <span>No</span>
                                    </div>
                                </div>
                            </div>  

                            {(field.triggersFollowUp === 'true' ||  field.triggersFollowUp === true) && 

                                    <>
                                    <div className="fields">
                                        <h2>Follow up questions</h2>
                                            <div className="field">
                                                <div className="radio">
                                                <>
                                                    <input name={"type-" + index} type="radio" value={'medical'} onChange={(e) => this.updateFollowOption(e, index)} checked={field.followUps.type === 'medical'}/>
                                                    <span>Medical</span>

                                                    <input name={"type-" + index} type="radio" value={'questionIds'} onChange={(e) => this.updateFollowOption(e, index)} checked={field.followUps.type === 'questionIds'}/>
                                                    <span>Questions within the DB</span>
                                                </>
                                             
                                                {field.followUps.type === 'questionIds' && 
                                                    <>
                                                    <div className="sub-field">
                                                        {field.followUps.questions.map((question, questionIndex)=> (
                                                            <>
                                                            <select name="question" value={question} onChange={(e) => this.updateFollowUpQuestion(e, index, questionIndex)}  defaultValue="placeholder" required={true}>
                                                                <option value="placeholder" disabled={true}>Please choose a question</option>
                                                                {this.props.questions.map(questionEntity => (
                                                                    <option value={questionEntity.id}>{questionEntity.value}</option>
                                                                ))}
                                                            </select>

                                                            <div className="buttons">
                                                                {((questionIndex === 0 && questionIndex < field.followUps.questions.length - 1) || questionIndex !== 0)&& <div onClick={() => {this.removeFollowUpQuestion(index, questionIndex)}} className="red button">remove</div>}
                                                                {questionIndex === field.followUps.questions.length - 1 && <div onClick={() => {this.addFollowUpQuestion(index)}} className="add blue button"></div>}
                                                            </div>
                                                            </>
                                                        ))}
                                                    </div>
                                                    </>
                                                }

                                                </div>
                                                
                                            </div>

                                    </div>
                                    </>
                            }
                        </div>
                            <div className="buttons">
                                {((index !== 0) || (index === 0 && this.state.options.length !== 1)) && <div onClick={() => {this.removeOption(index)}} className="button red remove question clearfix">Remove option</div>}
                                {index === this.state.options.length - 1 && <div onClick={() => {this.addOption()}} className="button blue add question clearfix">Add option</div>}
                            </div>
                        </>
                        ))}
                        </>
                    }

				</div>
			</form>
		)
	}
}


export default QuestionsForm;