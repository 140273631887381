import axios from 'axios'
import User from './user.jsx'

class API {

	// API constructor
	constructor() {
		// this.apiURL = 'http://pacific-prime.staging.mediasia.cn'
		this.apiURL =  process.env.REACT_APP_API_URL
		this.headers = {}
		this.token = null
	}

	setToken(token) {
		this.token = token
	}

	/**
	 * CRUD functions
	 */

	// Get list of entities from given model
	list(model) {
		return this.request('get', model)
	}

	// Create new entity on given model
	create(model, fields) {
		return this.request('post', model, fields)
	}

	// Read entity from the api for a given model and id
	read(model, id) {
		return this.request('get', model + '/' + id)
	}

	// Update an entity on the given model
	update(model, id, fields) {
		return this.request('put', model + '/' + id, fields)
	}

	// Delete an entity
	delete(model, id) {
		return this.request('delete', model + '/' + id)
	}

	/** 
	 * HTTP request
	 */

	request(method, path, data) {
		return new Promise((resolve, reject) => {
			axios({
				method: method,
				baseURL: this.apiURL,
				url: path,
				data: data ? data : null,
				headers: {
					authorization: 'Bearer ' + this.token,
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				if(err.response && err.response.data.logoutApplication) {
					User.logout()
					window.location.href="/login"
				}	
				reject(err)
			})
		})
	}

	// applications
	getApplications(params) {
		params = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : ''
		console.log(params)
		return this.list('applications' + params)
	}

	getQrCode(id) {
		return this.request('get', `applications/${id}/get-qr-code`)
	}

	getQuestionsAndApplication(id, section) {
		return this.request('get', `applications/${id}/get-questions/${section}`)
	}

	createPdf(id) {
		return this.create(`applications/${id}/create-pdf`)
	}

	putApplications(id, fields) {
		return this.request('put', `applications/${id}`, fields)
	}

	putAnswers(id, fields, type) {
		return this.request('put', `applications/${id}/add-answers/${type}`, fields)
	}

	updateApplicationStatus(id, fields) {
		return this.request('put', `applications/${id}/update-status`, fields)
	}

	updateProspect(id, fields) {
		return this.request('put', `applications/${id}/update-prospect`, fields)
	}


	// operators
	getOperators(params) {
		params = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : ''
		return this.list('users' + params)
	}

	getOperator(id) {
		return this.read('users', id)
	}

	
	// providers
	getProviders(params) {
		params = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : ''
		return this.list('providers' + params)
	}

	getProvider(id) {
		return this.read('providers', id)
	}

	// plans
	getPlans(params) {
		params = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : ''
		return this.list('plans' + params)
	}
	
	getPlan(id) {
		return this.read('plans', id)
	}

	listProviderPlans() {
		return this.list('providers/plans')
	}

	putsQuestionsToPlan(id, fields) {
		return this.request('put', `plans/${id}/add-questions`, fields)
	}

	putsOneQuestionToPlan(id, section, fields) {
		return this.request('put', `plans/${id}/add-one-question/${section}`, fields)
	}

	getPlansFromProvider(id) {
		return this.list(`plans/providers/${id}`)
	}

	deleteQuestionFromPlan(id, section, field) {
		return this.request('delete', `plans/${id}/remove-question/${section}`, field)
	}

	getQuestionsFromPlan(id, section) {
		return this.request('get', `plans/${id}/questions/${section}`)
	}

	putsPdfTemplate(id, file) {
		return this.putAsset(`plans/${id}/upload-pdf`, file)
	}

	putsMaxNoOfDependants(id, data) {
		return this.request('put', `plans/${id}/update-max-dependants`, data)
	}

	syncQuestionOrder(id, section, entities, subSection = null) {
		return this.request('put', `plans/${id}/sync-order/${section}/${subSection}`, entities)
	}


	// pages
	getPages(params) {
		params = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : ''
		return this.list('pages' + params)
	}

	getPage(page, id) {
		return this.read(`pages/${page}`, id)
	}

	postTempImage(file) {
		return this.postAsset('pages/show-temp-image', file)
	}


	// all 
	putSaveNewImageFiles(id, model, files) {
		return this.putAsset(`${model}/${id}/save-file-and-entity`, files)
	}

	putResaveCurrentImages(id, model, data) {
		return this.request('put', `${model}/${id}/resave-current`, data)
	}


	putAsset(path, files) {
		return new Promise((resolve, reject) => {
			if(!this.token) {
				reject({noToken: true})
			}
			axios({
				baseURL: this.apiURL,
				url: path,
				data: files,
				method: 'put',
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: 'Bearer ' + this.token
				}
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				if(err.response && err.response.data.logoutApplication) {
					User.logout()
					window.location.href="/login"
				}	
				reject(err)
			})
		})
	}

	postAsset(path, file) {
		var formData = new FormData()
		formData.append('file', file)
		formData.append('data', JSON.stringify({}))
		return new Promise((resolve, reject) => {
			if(!this.token) {
				reject({noToken: true})
			}
			axios({
				baseURL: this.apiURL,
				url: path,
				data: formData,
				method: 'post',
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: 'Bearer ' + this.token
				}
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				if(err.response && err.response.data.logoutApplication) {
					User.logout()
					window.location.href="/login"
				}	
				reject(err)
			})
		})
	}
}

export default new API();



