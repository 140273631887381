import React, { useState, useEffect } from 'react'

const nationalityStrings = 
{
  english: 
  [ 
    "Afghanistan",
    "African Intellectual Property Organization",
    "African Regional Intellectual Property Organization",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua and Barbuda",
    "Antilles",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belis",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman",
    "Cayman Islands ",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Commonwealth of Independent States",
    "Comoros",
    "Congo",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curacao",
    "Cyprus",
    "Czech",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Dutch Caribbean",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Enquilla",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea Bissau",
    "Gulf Arab States Cooperation Council Patent Office",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong-Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Isle of Man",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kalimantan Island",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Malvinas",
    "Marshall",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Nevis",
    "New England",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "North Korea",
    "North Macedonia",
    "Northern Mariana",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Polynesia",
    "Portugal",
    "Portuguese Madeira",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saba",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "SAO Tome and Principe",
    "Saudi Arabia",
    "Scotland",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovak ",
    "Slovenia",
    "Solomon",
    "Somalia",
    "South Africa",
    "South Georgia and South Sandwich",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "St. Eustace",
    "St. Helena, Ascension and Tristan Da Cunha",
    "St. Martin",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan,China",
    "Tajikistan",
    "Tanzania",
    "Tatarstan",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican",
    "Venezuela",
    "Viet nam",
    "Virgin Islands",
    "Virgin Islands",
    "West Indies",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe"
  ],
  chinese: [
    "阿富汗",
    "非洲知识产权组织",
    "非洲地区知识产权组织",
    "阿尔巴尼亚",
    "阿尔及利亚",
    "安道尔",
    "安哥拉",
    "安圭拉",
    "安提瓜和巴布达",
    "安的列斯",
    "阿根廷",
    "亚美尼亚",
    "阿鲁巴",
    "澳大利亚",
    "奥地利",
    "阿塞拜疆",
    "巴哈马",
    "巴林王",
    "孟加拉国",
    "巴巴多斯",
    "白俄罗斯",
    "比利时",
    "贝里斯",
    "伯利兹",
    "贝宁",
    "百慕大",
    "不丹",
    "玻利维亚国",
    "博内尔岛,圣尤斯特歇斯和萨巴",
    "波斯尼亚和黑塞哥维那",
    "博茨瓦纳",
    "布维岛",
    "巴西",
    "文莱达鲁萨兰",
    "保加利亚",
    "布基纳法索",
    "布隆迪",
    "佛得角",
    "柬埔寨",
    "喀麦隆",
    "加拿大",
    "开曼",
    "开曼",
    "中非",
    "乍得",
    "智利",
    "中国",
    "哥伦比亚",
    "独立国家联合体",
    "科摩罗",
    "刚果",
    "刚果",
    "库克",
    "哥斯达黎加",
    "克罗地亚",
    "古巴",
    "库拉索",
    "塞浦路斯",
    "捷克",
    "丹麦",
    "吉布提",
    "多米尼克",
    "多米尼加",
    "加勒比地区",
    "厄瓜多尔",
    "埃及",
    "萨尔瓦多",
    "安奎拉",
    "赤道几内亚",
    "厄立特里亚",
    "爱沙尼亚",
    "埃塞俄比亚",
    "法罗",
    "斐济",
    "芬兰",
    "法国",
    "加蓬",
    "冈比亚",
    "格鲁吉亚",
    "德国",
    "加纳",
    "直布罗陀",
    "直布罗陀",
    "希腊",
    "格陵兰岛",
    "格林纳达",
    "危地马拉",
    "根西",
    "几内亚",
    "几内亚比绍",
    "海湾阿拉伯国家合作委员会专利局",
    "圭亚那",
    "海地",
    "洪都拉斯",
    "香港",
    "匈牙利",
    "冰岛",
    "印度",
    "印度尼西亚",
    "伊朗伊斯兰",
    "伊拉克",
    "爱尔兰",
    "马恩岛",
    "人岛",
    "以色列",
    "意大利",
    "科特迪瓦",
    "牙买加",
    "日本",
    "泽西",
    "约旦",
    "加曼",
    "哈萨克斯坦",
    "肯尼亚",
    "基里巴斯",
    "科威特",
    "吉尔吉斯斯坦",
    "老挝",
    "拉脱维亚",
    "黎巴嫩",
    "莱索托",
    "利比里亚",
    "利比亚",
    "列支敦士登",
    "立陶宛",
    "卢森堡",
    "澳门",
    "马其顿",
    "马达加斯加",
    "马拉维",
    "马来西亚",
    "马尔代夫",
    "马里",
    "马耳他",
    "马尔维纳斯",
    "马绍尔",
    "毛里塔尼亚",
    "毛里求斯",
    "墨西哥",
    "密克罗尼西亚",
    "摩尔多瓦",
    "摩纳哥",
    "蒙古",
    "黑山",
    "黑山",
    "蒙特塞拉特",
    "摩洛哥",
    "莫桑比克",
    "缅甸",
    "纳米比亚",
    "瑙鲁",
    "尼泊尔",
    "荷兰",
    "尼维斯",
    "纽英",
    "新西兰",
    "尼加拉瓜",
    "尼日尔",
    "尼日利亚",
    "纽埃",
    "朝鲜",
    "北马其顿",
    "北马里亚纳",
    "挪威",
    "阿曼",
    "巴基斯坦",
    "帕劳",
    "巴勒斯坦",
    "巴拿马",
    "巴布亚新几内亚",
    "巴拉圭",
    "秘鲁",
    "菲律宾",
    "波兰",
    "波利尼西亚",
    "葡萄牙",
    "葡属马德拉",
    "波多黎各",
    "卡塔尔",
    "罗马尼亚",
    "俄罗斯",
    "卢旺达",
    "萨巴",
    "圣基茨和尼维斯",
    "圣卢西亚",
    "圣文森特和格林纳丁斯",
    "萨摩亚",
    "圣马利诺",
    "圣多美和普林西比",
    "沙特阿拉伯",
    "苏格兰",
    "塞内加尔",
    "塞尔维亚",
    "塞舌尔",
    "塞拉利昂",
    "新加坡",
    "斯洛伐克",
    "斯洛文尼亚",
    "所罗门",
    "索马里",
    "南非",
    "南乔治亚岛与南桑威奇",
    "韩国",
    "南苏丹",
    "西班牙",
    "斯里兰卡",
    "圣尤斯特歇斯",
    "圣赫勒拿、阿森松与特里斯坦达库尼亚",
    "圣马丁",
    "苏丹",
    "苏里南",
    "斯威士兰",
    "瑞典",
    "瑞士",
    "叙利亚",
    "中国台湾",
    "塔吉克斯坦",
    "坦桑尼亚",
    "鞑靼斯坦",
    "泰国",
    "东帝汶",
    "多哥",
    "汤加",
    "特立尼达和多巴哥",
    "突尼斯",
    "土耳其",
    "土库曼斯坦",
    "特克斯和凯科斯",
    "图瓦卢",
    "乌干达",
    "乌克兰",
    "阿拉伯联合酋长国",
    "英国",
    "美国",
    "乌拉圭",
    "乌兹别克斯坦",
    "瓦努阿图",
    "梵蒂冈",
    "委内瑞拉",
    "越南",
    "维尔京",
    "维尔京",
    "西印度",
    "西撒哈拉",
    "也门",
    "赞比亚",
    "津巴布韦"
  ]
}

const SelectNationality = props => {
    const [value, setValue] = useState(null)
    const handleChange = e => {
        if(!props.field)
            props.updateOther(e.target.value, props.name)
        else
            props.updateField(e.target.value, props.name)
    }
    useEffect(() => {
        const chineseIndex = nationalityStrings['chinese'].findIndex(c => c === props.value)
        const englishIndex = nationalityStrings['english'].findIndex(c => c === props.value)

        // this will ensure that the nationality is always displayed in English on the BO (regardless of the person's answer from the MP)
        if(chineseIndex > -1) {
            setValue(nationalityStrings.english[chineseIndex])
        } else if (englishIndex > -1) {
            setValue(nationalityStrings.english[englishIndex])
        } 
        
    }, [props.value])

    return (
        <select key={props.name} onChange={(e) => handleChange(e)} name={props.name} value={value} required={props.required ? true : false} defaultValue="nation">
            <option value="nation" disabled={true}>Nationality</option>
            {nationalityStrings.english.map(country => (
                <option value={country}>{country}</option>
            ))}

        </select>
    )
}

export default SelectNationality
