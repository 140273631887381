import React from 'react';
import API from '../../services/api.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import Loader from '../layout/loader.jsx'
// import moment from 'moment'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'

class QuestionsList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
            filters: {
				value: ''
			},
			questions: {
				columns: [
                {
					label: 'ID',
					key: 'value'
				},
                {
                    label: 'Answer Type',
                    key: 'answerType'
                },
                {
                    label: 'Multiple choice',
                    key: 'options',
                    view: (field) => {
                        if(field.length) {
                            return 'true'
                        } else {
                            return 'false'
                        }
                    }
                },
				{
					label: 'Required',
                    key: 'required',
                    view: (field) => {
                        if(field === true) {
                            return 'true'
                        } else {
                            return 'false'
                        }
                    }
				},
				{
					label: 'Language',
                    key: 'language',
					view: (field) => {
                        if(field === 'both') {
                            return 'English & Chinese'
                        } else {
                            return field[0].toUpperCase() + field.slice(1)
                        }
                    }
				},
                {
                    action: 'edit and delete',
                    realm: 'superAdmin',
                    onClickEdit: (entityId) => {
                        this.props.history.push('/questions/' + entityId + '/edit')
                    },
                    onClickDelete: (id, key) => {
                        var r = window.confirm("Are you sure you want to delete this question?");
                        if (r === false) return

                        this.setState({ loading: true })
                        API.delete('questions', id).then(() => {
                            toast.success('Question was successfully deleted')
                            this.loadEntities()
                        }).catch(() => {
                            toast.success('An unexpected error has occured')
                            this.setState({ loading: false })
                        })
                    }
                }],
				entities: [],
				filters: [{
					label: 'Search for question',
					onChange: debounce(value => {
						// eslint-disable-next-line array-callback-return
						let entities = this.state.questions.originalEntities.filter(entity => {
							if(!value) {
								return entity
							} else {
								const valueInEntity = entity.value.toLowerCase()
								if(value) {
									return valueInEntity.toLowerCase().includes(value)
								}
							}
						})

						this.setState({...this.state, questions: {...this.state.questions, entities}})
					}, 100)
				}],
				actions: [
					{
						classList: 'blue add',
						label: 'New question',
						realm: 'superAdmin',
						onClick: () => {
							this.props.history.push('/questions/new')
						}
					}
				],
				selectedItems: [],
				multiSelect: false
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
		API.list('questions', this.state.filters)
			.then(entities => {
                // alphabeticallty sort
                entities.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

				this.setState({...this.state, loading: false, questions: {...this.state.questions, entities, originalEntities: entities} })
			})
			.catch(err => {
				console.log(err)
			})
	}

	viewQuestion(id) {
		this.props.history.push('/questions/' + id );
	}

	render() {
		if(this.state.loading)
			return <Loader />
		return <>
			<Head title={'Questions'} />
			<Content>
				<Table settings={this.state.questions} />				
			</Content>
		</>
	}
}

export default QuestionsList;