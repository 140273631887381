import React from 'react';
import API from '../../services/api.jsx'
import User from '../../services/user.jsx'
import Loader from '../layout/loader.jsx'
import Table from '../layout/table.jsx'
import Content from '../layout/content.jsx'
import Head from '../layout/head.jsx'
import moment from 'moment'
import { toast } from 'react-toastify'

class ApplicationList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			filters: {
				user: User.current().id
			},
			applications: {
				columns: [
					{
						label: 'Client name',
						key: 'firstName lastName',
						canSort: true,
						view: (field, entity) => {
							return (entity.firstName ? entity.firstName : '') + ' ' + (entity.lastName ? entity.lastName : '')
						}
					}, 
					{
						label: 'Company',
						key: 'plan',
						view: field => {
							if(field.provider === null || field.provider === undefined) {
								return ''
							} else {
								return field.provider.name
							}
						}
					},
					{
						label: 'Plan',
						key: 'plan',
						view: field => {
							return field.name
						}	
					}, 
					{
						label: 'Creation date',
						key: 'createdAt',
						canSort: true,
						view: function(field){
							return moment(field).format('YYYY-MM-DD')
						},
						onSortClick: column => {
							console.log(column, 'column')
						}
					}
					,{
						label: 'Last update',
						key: 'updatedAt',
						canSort: true,
						view: function(field){
							return moment(field).format('YYYY-MM-DD')
						},
						onSortClick: column => {
							console.log(column, 'column')
							
						}
					},
					{
						label: 'Operator',
						key: 'operator',
						canSort: true,
						view: function(field) {
							if(field !== null)
								return field.name
						}
					},
					{
						action: 'select',
						label: 'Status',
						props: {
							options: [
								{ value: 'new', label: 'NEW' },
                                { value: 'in-progress', label: 'IN PROGRESS' },
                                { value: 'to-be-signed', label: 'NEED TO BE SIGNED' },
                                { value: 'signed', label: 'SIGNED' },
                                { value: 'cancelled', label: 'CANCELLED' },
							],
						},
						onChange: (e, entity) => {
							let nextElement = {}

							const newEntities = this.state.applications.entities.map(element => {
								if(element.id !== entity.id) {
									return element
								} else {
									nextElement = {...element, status: e.target.value}

									return nextElement
								}
							})

							// now need to make the API call
							this.setState({
								...this.state,
								loading: false,
								applications: {
									...this.state.applications,
									entities: newEntities
								}
							}, async () => {
									try {
										await API.updateApplicationStatus(entity.id, nextElement);
										this.setState({ loading: false });
										toast.success("The application's status has been updated");
									} catch (err) {
										this.setState({ loading: false });
										console.error(err);
										toast.error('An unexpected error has occured, please try again');
									}
							})

						}
					}, 
					{
						action: 'edit',
						onClick: (entityId) => {
							this.props.history.push('/applications/' + entityId + '/section/plans')
						}
					},
					{
						action: 'delete',
						realm: 'superAdmin',
						onClick: (id, key) => {
							var r = window.confirm("Are you sure you want to delete this application?");
							if (r === false) return
	
							this.setState({ loading: true })
							API.delete('applications', id).then(() => {
								toast.success('Application was successfully deleted')
								this.loadEntities()
							}).catch(() => {
								toast.success('An unexpected error has occured')
								this.setState({ loading: false })
							})
						}
					}
				],
				entities: [],
				filters: [{
					label: 'Search for a client',
					onChange: value => {
						// eslint-disable-next-line array-callback-return
						let entities = this.state.applications.originalEntities.filter(entity => {
							if(!value) {
								return entity
							} else {
								const firstName = entity.firstName && entity.firstName.toLowerCase()
								const lastName = entity.lastName && entity.lastName.toLowerCase()
								if(firstName && lastName) {
									let name = firstName + ' ' + lastName
									return name.toLowerCase().includes(value)
								}
							}
						})

						this.setState({...this.state, applications: {...this.state.applications, entities}})
					}
				}],
				actions: [{
					classList: 'blue add',
					label: 'New application',
					onClick: () => {
						this.props.history.push('/applications/new/plans')
					}
				}],
				selectedItems: [],
				multiSelect: false
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
		API.getApplications(this.state.filters).then(entities => {
			const newEntities = []

			for(const i in entities) {
				const entity = entities[i]
				if(entity.plan && entity.plan.provider) {
					entity.plan = JSON.parse(JSON.stringify(entity.plan))
					entity.plan.provider = JSON.parse(JSON.stringify(entity.plan.provider))
					newEntities.push(entity)
				}
			}

			const mostRecentFirst = newEntities.reverse()

			this.setState({ ...this.state, loading: false, applications: {...this.state.applications, entities: mostRecentFirst, originalEntities: mostRecentFirst}})
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else {
			return (
			<>
			<Head title={'Applications'} />
			<Content>
				<Table settings={this.state.applications}/>
			</Content>
			</>
		
			)
		}
	}
}

export default ApplicationList;