import React from 'react';
import Field from '../field.jsx';
import FormActions from '../formActions'


class Form extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			questions: props.questions,
            noOfDependants: props.noOfDependants,
            isProspect: props.isProspect,
            answers: props.answers,
            nameAnswers: props.nameAnswers
		}
	}

    updateName = (value, key, index) => {
        let nameAnswers = JSON.parse(JSON.stringify(this.state.nameAnswers))
        nameAnswers[index] = {...nameAnswers[index], [key]: value}

        this.setState({...this.state, nameAnswers})
    }

    updateOther = (value, key) => {
        this.setState({
            ...this.state,
            [key]: value
        })

	}

	updateField = (value, key, section = null) =>{
		const answers = this.state.answers
		let newSectionAnswers = answers.map((answer) => {
			if (answer.questionId === key) {
				answer.value = value
			} 
			return answer
		})

		this.setState({...this.state, answers: newSectionAnswers })
	}

	removeNestedAnswers = arrayOfIds => {
		const answers = [...this.state.answers]
		
		answers.map(answer => {
			const answerFiltered = arrayOfIds.filter(id => id === answer.questionId)
			const answerMatched = answerFiltered.length > 0

			if(answerMatched) {
				answer.value = null
			}

			return answer
		})

		this.setState({...this.state, answers})
	}

	returnAnswerValue = id => {
		const answers = this.state.answers
		let findAnswer = answers.find((a) => a.questionId === id)

		return findAnswer && findAnswer.value
	}

    addDependant = () => {
        let nameAnswers = JSON.parse(JSON.stringify(this.state.nameAnswers))
        nameAnswers.push({firstName: null, lastName:null})

        this.setState({
            ...this.state,
            nameAnswers,
            noOfDependants: this.state.noOfDependants + 1
        })
    }

    replaceClickedArrayWithNextArray = (arrayOfArrays, nextArray, index) => {
        arrayOfArrays[index] = nextArray
        return arrayOfArrays
    }

    removeDependant = index => {
        let nameAnswers = JSON.parse(JSON.stringify(this.state.nameAnswers))
        let answersCopy = JSON.parse(JSON.stringify(this.state.answers))
        let nextAnswersCopy = JSON.parse(JSON.stringify(this.state.answers))
        let questionsCopy = JSON.parse(JSON.stringify(this.state.questions))

        const keys = Object.keys(questionsCopy[0])
        const newInsuredOne = []

        keys.map(key => {
            return newInsuredOne.push(questionsCopy[0][key])
        })
        
        questionsCopy[0] = [].concat(...newInsuredOne)
        // get all the questions at every level and put into an array of objects
        // find any that say insured x (insured x is being deleted) 
        // find any that are after insured x

        // loop through questions and find any that are insured x 
        this.props.getAllQuestions(questionsCopy).then(insuredQuestions => {
            let prevInsuredCount = 0
            let nextInsuredCount = index
            let updatedAnswers = []
            // save prev answers to updated answers
            while(prevInsuredCount < nextInsuredCount) {
                let keepAnswersRegex = new RegExp(`insured${prevInsuredCount + 1}`)
                let insuredToKeepQs = insuredQuestions.filter(q => q.value.match(keepAnswersRegex))

                for(const i in insuredToKeepQs) {
                    const question = insuredToKeepQs[i]
                    let foundAnswer = nextAnswersCopy.find(a => a.questionId === question.id)

                    if(foundAnswer !== undefined) {
                        updatedAnswers.push(foundAnswer)
                    }
                }
                prevInsuredCount ++
            }

            // save next answers to updated answers
            while(nextInsuredCount < this.state.questions.length) {
                let insuredCurrentRegex = new RegExp(`insured${nextInsuredCount + 1}`)
                let allExceptInsuredCurrent = new RegExp(`[^${insuredCurrentRegex}].*`)
                let insuredNextRegex = new RegExp(`insured${nextInsuredCount + 2}`)
                let insuredCurrent = insuredQuestions.filter(q => q.value.match(insuredCurrentRegex))
                let insuredNext = nextInsuredCount < this.state.questions.length - 1 ? insuredQuestions.filter(q => q.value.match(insuredNextRegex)) : []
                let foundCurrentQuestion
                // delete current answers and move next one to previous one
                for(const i in answersCopy) {
                    const answer = answersCopy[i]
                    foundCurrentQuestion = insuredCurrent.filter(q => q.id === answer.questionId)
                    // current question found
                    if(foundCurrentQuestion.length > 0) {
                        // match everything in current question except insured1_ insured2_ etc.
                        const matchAllExceptInsured = foundCurrentQuestion[0].value.match(allExceptInsuredCurrent)
                        // find the next question that matches everything except for insured1_ insured2_ etc
                        const nextQuestion = insuredNext.find(q => q.value.match(matchAllExceptInsured))
                        // if next question matches the current question except for insured1_ insured2_ etc.
                        if(nextQuestion !== undefined) {
                            // find next answer by using the id
                            const nextAnswer = nextAnswersCopy.find(a => a.questionId === nextQuestion.id)

                            // if next answer is found
                            if(nextAnswer !== undefined) {
                                // set next answer value to current question
                                updatedAnswers.push({questionId: foundCurrentQuestion[0].id, value: nextAnswer.value})
                            }
                        // next question not found so set the current questionId to an empty string
                        } else {
                            updatedAnswers.push({questionId: foundCurrentQuestion[0].id, value: ''})
                        }
                    }
                }
                // repeat on next set
                nextInsuredCount ++
            }   

            // remove nameAnswers array for dependant being deleted
            nameAnswers.splice(index, 1)

            this.setState({questions: questionsCopy, answers: updatedAnswers, nameAnswers, noOfDependants: this.state.noOfDependants - 1})
        })
    }

	onSubmit(e, callback) {
		e.preventDefault()
        callback()
	}

	render() {
		return (
			<form onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state))}>
				<FormActions />
                
				<div className="block dependants">
                    <>
                    <div className="title">
                        <h2>{this.props.isInsuredOneDependantOne ? 'Dependant 1' : 'Insured 1'}</h2>
                    </div>
                    <div className="fields clearfix wrap">
                        <div className="field">
                            {/* first name */}
                            <label>First name (as in ID or passport)</label>
                            <input type="text" name="firstName" onChange={(e) => this.updateName(e.target.value, e.target.name, 0)} value={this.state.nameAnswers[0].firstName}/>
                        </div>
                        <div className="field">
                            {/* last name */}
                            <label>Last name (as in ID or passport)</label>
                            <input type="text" name="lastName" onChange={(e) => this.updateName(e.target.value, e.target.name, 0)} value={this.state.nameAnswers[0].lastName}/>
                        </div>
                        {
                            this.props.questions[0].map((field, questionIndex) => (
                                <Field id={0 + this.state.noOfDependants + 0 + questionIndex + this.state.noOfDependants} returnAnswerValue={this.returnAnswerValue} field={field} updateField={this.updateField} updateOther={this.updateOther} removeNestedAnswers={this.removeNestedAnswers} initialRender/>
                            ))
                        }
                    </div>
                    </>
                    {Object.keys(this.state.questions).map((questionKey, index) => {
                        const firstIndex = index === 0

                        return (
                            !firstIndex &&
                            index <= this.state.noOfDependants && 
                            <>
                            <div className="title">
                                <h2>{this.props.isInsuredOneDependantOne ? `Dependant ${index + 1}` : `Insured ${index + 1}`} {index !== 0 && <span onClick={() => this.removeDependant(index)} className="delete">Delete</span> }</h2>
                            </div>
                            <div className="fields clearfix wrap">
                                <div className="field">
                                    {/* first name */}
                                    <label>First name (as in ID or passport)</label>
                                    <input type="text" name="firstName" onChange={(e) => this.updateName(e.target.value, e.target.name, index)} value={this.state.nameAnswers[index].firstName}/>
                                </div>
                                <div className="field">
                                    {/* last name */}
                                    <label>Last name (as in ID or passport)</label>
                                    <input type="text" name="lastName" onChange={(e) => this.updateName(e.target.value, e.target.name, index)} value={this.state.nameAnswers[index].lastName}/>
                                </div>

                                {
                                    this.props.questions[index].map((field, questionIndex) => (
                                        <Field id={questionKey + index + questionIndex + this.state.noOfDependants} returnAnswerValue={this.returnAnswerValue} field={field} updateField={this.updateField} updateOther={this.updateOther} removeNestedAnswers={this.removeNestedAnswers} initialRender/> 
                                    ))
                                }
                            </div>
                            </>
                        )
                    })}
                    
                    {this.state.noOfDependants < this.props.maxNoOfDependants - 1 &&
                        <div className="buttons clearfix left">
                            <div className="button blue transparent rectangle add" onClick={(e) => this.addDependant()}>Add another dependant</div>
                        </div>
                    }
				</div>

			</form>
		)
	}
}


export default Form;