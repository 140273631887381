import React from 'react'
import Rodal from 'rodal'
import Select from 'react-select'
import { toast } from 'react-toastify'
import API from '../../../services/api.jsx'

class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedQuestion: {},
            questionNumber: null,
            selectedSection: props.selectedSection,
            availableQuestions: props.availableQuestions
        }
    }

	handleQuestionChange = (value) => {
		this.setState({
			...this.state,
			selectedQuestion: value
		})
	}

	addQuestion = () => {
		let newObj = {
			[this.props.selectedSection.value]: {
				question: this.state.selectedQuestion.id,
                questionNumber: this.state.questionNumber
			}
		}

		API.putsOneQuestionToPlan(this.props.planId, this.props.section, newObj).then(() => {
			toast.success(`Question successfully added to ${this.props.selectedSection.label}`);
			this.props.hidePopup()
		}).catch(err => {
			console.log(err.status)
			toast.error('An unexpected error has occured, please try again');
		})
	}

	updateQuestionNumber = e => {
		this.setState({
			...this.state,
			questionNumber: e.target.value
		})
	}

    render() {
        return(
            <Rodal visible={this.props.popupVisible} onClose={() => this.props.hidePopup()} width={790} height={574}>
                <div className="popup-inner">
                    <div className="header"></div>
                    <h2>{this.props.selectedSection && this.props.selectedSection.label}</h2>
                    <div className="field">
                        <label>Question value</label>
                        <Select 
                            value={this.state.selectedQuestion}
                            onChange={this.handleQuestionChange}
                            options={this.state.availableQuestions}
                            getOptionLabel={question => question.value}
                            getOptionValue={question => question.id}
                        />
                    </div>

                    <div className="field">
                        <label>Question number written on PDF (optional)</label>
                        <input type="text" onChange={(e) => this.updateQuestionNumber(e)} value={this.state.questionNumber}/>
                    </div>
                    <div className="actions">
                        <button className="blue" onClick={() => this.addQuestion()}>Save</button>
                        <button className="transparent" onClick={() => this.props.hidePopup()}>Cancel</button>				
                    </div>
                </div>
            </Rodal>
        )
    }
}

export default Modal