import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import ProvidersForm from './form.jsx';

class ProviderEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			breadcrumb: { title: 'View plans', path: '/providers/' + props.match.params.providerId + '/plans' },
			fields: {
				name: { label: 'Name', type: 'text', required: true }
            },
            provider: null,
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	componentDidMount() {
		if(this.props.match.params.providerId !== '') {
			API.getProvider(this.props.match.params.providerId).then(provider => {
				console.log(provider)
				this.setState({...this.state, loading: false, provider: provider })
			})
		}
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		API.update('providers', this.props.match.params.providerId, fields.entity).then(() => {
			toast.success('Provider has been updated')
			this.props.history.push('/providers/')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	deleteEntity = () => {
		var r = window.confirm("Are you sure you want to delete this question?");
		if (r === false) return

		this.setState({ loading: true })
		API.delete('providers', this.props.match.params.providerId).then(() => {
			toast.success('Provider was successfully deleted')
			this.props.history.push('/providers/')
		}).catch(() => {
			toast.success('An unexpected error has occured')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Edit ' + (this.state.provider.name)} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs} />
				<Content>
					<ProvidersForm 
						fields={this.state.fields} 
						entity={this.state.provider} 
						cancel={() => this.props.history.push('/providers')} 
						save={this.saveEntity} 
						deleteEntity={this.deleteEntity}
					/>
				</Content>
			</>
	}
}

export default ProviderEdit;