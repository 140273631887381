import React, { useState, useEffect } from 'react';

function SelectComponent({...props}) {
	let [className, setClassName] = useState('')
	let status = props.entity.status

	const onChange = e => {
		props.onChange(e, props.entity)
	}

	useEffect(() => {
		switch (status) {
			case 'new':
				return setClassName('red')
			case 'in-progress':
				return setClassName('yellow')
			case 'to-be-signed':
				return setClassName('blue')
			case 'signed':
				return setClassName('green')
			case 'cancelled':
				return setClassName('grey')
			default:
				return setClassName('red')
		}
	}, [status])

	return (
		<div className={'select-custom ' + className} >
			<select onChange={(e) => onChange(e)} value={status}>
				<option value="new">NEW</option>
				<option value="in-progress">IN PROGRESS</option>
				<option value="to-be-signed">NEEDS TO BE SIGNED</option>
				<option value="signed">SIGNED</option>
				<option value="cancelled">CANCELLED</option>
			</select>
		</div>
	);
}

export default SelectComponent;