import React from 'react';
import API from '../../services/api.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Loader from '../layout/loader.jsx'
// import moment from 'moment'
import { toast } from 'react-toastify'
// import { debounce } from 'lodash'
import QuestionsForm from './form.jsx';

class QuestionsEdit extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: false,
            fields: {
                answerType: {
                    options: [
                        {key: 'string', label: 'String'},
                        {key: 'email', label: 'Email'},
                        {key: 'number', label: 'Number'},
                        {key: 'date', label: 'Date'},
                        {key: 'address', label: 'Address'},
                        {key: 'groupedQuestions', label: 'Group Questions'},
                        {key: 'textarea', label: 'Textarea'},
                        {key: 'select', label: 'Select'},
                        {key: 'radio', label: 'Radio'},
                        {key: 'checkboxGroup', label: 'Checkbox Group'},
                        {key: 'checkbox', label: 'Checkbox'},
                        {key: 'nationality', label: 'Country'},
                        {key: 'firstName', label: 'First name'},
                        {key: 'lastName', label: 'Last name'},
                        {key: 'fullName', label: 'Full name'},
                        {key: 'medicalFollowUp', label: 'Medical Follow Up'}
                    ],
                },
                date: {
                    options: [
                        {key: 'YYYY/MM/DD', label: 'YYYY/MM/DD'},
                        {key: 'DD/MM/YYYY', label: 'DD/MM/YYYY'},
                        {key: 'MM/DD/YYYY', label: 'MM/DD/YYYY'},
                        {key: 'YY/MM/DD', label: 'YY/MM/DD'},
                        {key: 'DD/MM/YY', label: 'DD/MM/YY'},
                        {key: 'MM/DD/YY', label: 'MM/DD/YY'},
                        {key: 'DD MM YYYY', label: 'DD MM YYYY'},
                    ]
                },
                language: {
                    options: [
                        {key: 'both', label: 'English & Chinese'},
                        {key: 'english', label: 'English'},
                        {key: 'chinese', label: 'Chinese'}
                    ]
                },
                followUpType: {
                    options: [
                        {key: 'medical', label: 'Medical'},
                        {key: 'question', label: 'Question'}
                    ]
                }
            },
			question: {
                value: '',
                label: {
                    english: '',
                    chinese: ''
                },
                answerType: 'string',
                language: 'both',
                required: false,
                addressQuestions: [null],
                groupQuestions: [null],
                dateType: null,
                showOnlyOnBackoffice: false,
                options: [
                {
                    value: '',
                    label: {
                        english: '',
                        chinese: ''
                    },
                    triggersFollowUp: false,
                    followUps: {
                        type: '',
                        questions: [undefined]
                    },
                }]
            }
		}
	}

	viewQuestion(id) {
		this.props.history.push('/questions/' + id );
	}

    componentDidMount() {
        API.list('questions')
			.then(entities => {
                // alphabeticallty sort
                entities.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
				this.setState({...this.state, loading: false, questions: entities })
			})
			.catch(err => {
				console.log(err)
			})
    }


    saveEntity = entity => {
        const multipleChoice = (entity.answerType === 'select') || (entity.answerType === 'radio')

        if(!multipleChoice) {
            entity.options = null
        }

        API.create('questions', entity)
            .then(question => {
                toast.success('Question has been created')
                this.props.history.push('/questions/')
            }).catch(() => {
                toast.error('An unexpected error has occured, please try again')
                this.setState({ loading: false })
            })
    }

	render() {
		if(this.state.loading)
			return <Loader />
		return <>
			<Head title={'New question'} />
			<Content>
				<QuestionsForm 
                    entity={this.state.question} 
                    questions={this.state.questions}
                    fields={this.state.fields}
                    cancel={() => this.props.history.push('/questions/')}
                    save={this.saveEntity}
                />				
			</Content>
		</>
	}
}

export default QuestionsEdit;