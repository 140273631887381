import React from 'react'
import Select from 'react-select'
import SelectComponent from '../applications/select-component.jsx'
import User from '../../services/user'

class Table extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedItems: props.settings.selectedItems
		}
		this.makeCheckbox = this.makeCheckbox.bind(this)
	}

	makeCheckbox(key) {
		let onChange = (e) => {
			e.stopPropagation()
			e.preventDefault()
			let selectedItems = this.state.selectedItems
			selectedItems[key] = !selectedItems[key]
			this.setState({ selectedItems: selectedItems })
		}
		return <label className="checkbox" onClick={(e) => onChange(e)}><input type="checkbox" checked={this.state.selectedItems && this.state.selectedItems[key]} /><span></span></label>
	}

	deleteRow(e) {
		e.preventDefault()
		e.stopPropagation()
		return true
	}

	render() {
		return (
			<>
				<div className="actions clearfix">
					<div className="filters clearfix">
						{
							this.props.settings.filters.map((action, i) => {
								if(action.type === 'select')
									return <div key={`field-${i}`} className="search select">
										<Select
											value={action.value}
											onChange={action.onChange}
											options={action.entities}
											getOptionLabel={entity => entity.name}
											getOptionValue={entity => entity.id}
										/>
									</div>
								else
									return <div key={`field-${i}`} className="search icon-bigsearch">
										<input type="text" placeholder={action.label} onChange={(e) => action.onChange(e.target.value)} />
									</div>
							})
						}
					</div>

					<div className="buttons clearfix">
						{
							// eslint-disable-next-line array-callback-return
							this.props.settings.actions.map((action, i) => {
								const realmPropExists = Object.keys(action).findIndex(c => c === 'realm') > 0

								if(realmPropExists) {
									if(User.hasRole(action.realm)) {
										return <button key={`button-${i}`} className={action.classList} onClick={action.onClick}>{action.label}</button>
									}
								} else {
									return <button key={`button-${i}`} className={action.classList} onClick={action.onClick}>{action.label}</button>
								}
							})
						}
					</div>
				</div>

				<div className="table-wrapper">
					{
						this.props.settings.entities && <table>
							<thead>
								<tr>
									{
										// eslint-disable-next-line array-callback-return
										this.props.settings.columns.map((column, key) => {
											const realmPropExists = Object.keys(column).findIndex(c => c === 'realm') > 0

											if(realmPropExists) {
												if(User.hasRole(column.realm)) {
													return <td className={column.canSort && 'sort-icon' } key={key}>{column.label}{column.canSort && <span onClick={() => column.onSortClick(column)}></span>}</td>
												} else {
													<td className={"empty-column" + (key === this.props.settings.columns.length - 1 ? ' last-child' : '')}></td>
												}
											} else {
												return <td className={column.canSort && 'sort-icon' } key={key}>{column.label}{column.canSort && <span onClick={() => column.onSortClick(column)}></span>}</td>
											}
										})
									}
								</tr>
							</thead>

							<tbody>
								{
									this.props.settings.entities.map((entity, rowKey) => {
										return <tr key={entity.id} onClick={() => this.props.settings.onRowClick && this.props.settings.onRowClick(entity)}>
											{
												// eslint-disable-next-line array-callback-return
												this.props.settings.columns.map((column, key) => {
													const realmPropExists = Object.keys(column).findIndex(c => c === 'realm') > 0
													if(column.action === 'up and down') {
														return (
															<>
															<td className="buttons">
																<button onClick={() => column.moveUp(entity.id)}>up</button>
																<button onClick={() => column.moveDown(entity.id)}>down</button>
															</td>
															</>
														)
													}
													if(realmPropExists) {
														if(User.hasRole(column.realm)) {
															if(column.action === 'edit and delete') {
																return(
																	<td className="edit-delete-column" key={entity.id + 'delete'}><p onClick={() => column.onClickEdit(entity.id)} className="edit-link with-delete">Edit</p><i className="icon-trash" onClick={(e) => this.deleteRow(e, entity.id, rowKey) && column.onClickDelete && column.onClickDelete(entity.id, rowKey)}></i></td>
																)
															}

															if(column.action === 'edit plan and questions and delete') {
																return(
																	<td className="edit-delete-column"><p onClick={() => column.onClickEdit(entity.id)} className="edit-link">Edit</p><p className="edit-link" onClick={() => column.onClickEditQuestions(entity.id)}>Edit Questions</p><i className="icon-trash" onClick={(e) => this.deleteRow(e, entity.id, rowKey) && column.onClickDelete && column.onClickDelete(entity.id, rowKey)}></i></td>
																)
															}

															if(column.action === 'select') {
																return (
																	<td key={entity.id + key} >
																		<SelectComponent 
																			onChange={column.onChange}
																			entity={entity}
																			options={column.props.options}
																		/>
																	</td>
																)
															}

															if(column.action === 'edit')
																return <td key={entity.id}><p onClick={() => column.onClick(entity.id)} className="edit-link">Edit</p></td>
															if(column.action === 'delete')
																return <td key={entity.id + 'delete'}><i className="icon-trash" onClick={(e) => this.deleteRow(e, entity.id, rowKey) && column.onClick && column.onClick(entity.id, rowKey)}></i></td>
														} else {
															return 	<td className={"empty-column" + (key === this.props.settings.columns.length - 1 ? ' last-child' : '')}></td>
														}
													} else {
														if(column.action === 'edit and delete') {
															return(
																<td className="edit-delete-column" key={entity.id + 'delete'}><p onClick={() => column.onClickEdit(entity.id)} className="edit-link with-delete">Edit</p><i className="icon-trash" onClick={(e) => this.deleteRow(e, entity.id, rowKey) && column.onClickDelete && column.onClickDelete(entity.id, rowKey)}></i></td>
															)
														}

														if(column.action === 'select') {
															return (
																<td key={entity.id + key} >
																	<SelectComponent 
																		onChange={column.onChange}
																		entity={entity}
																		options={column.props.options}
																	/>
																</td>
															)
														}
														if(column.action === 'edit')
															return <td key={entity.id}><p onClick={() => column.onClick(entity.id)} className="edit-link">Edit</p></td>
														if(column.action === 'delete' && !this.props.settings.multiSelect)
															return <td key={entity.id + 'delete'}><i className="icon-trash" onClick={(e) => this.deleteRow(e, entity.id, rowKey) && column.onClick && column.onClick(entity.id, rowKey)}></i></td>
														else {
															const hasMultiSelectRole = this.props.settings.multiSelectRole

															if(hasMultiSelectRole) {
																if(User.hasRole(hasMultiSelectRole)) {
																	return <td key={entity.id + key}>{this.props.settings.multiSelect && key === 0 && this.makeCheckbox(rowKey)} {column.view ? column.view(entity[column.key], entity) : entity[column.key]}</td>
																} else {
																	return <td key={entity.id + key}>{column.view ? column.view(entity[column.key], entity) : entity[column.key]}</td>
																}
															} else {
																return <td key={entity.id + key}>{this.props.settings.multiSelect && key === 0 && this.makeCheckbox(rowKey)} {column.view ? column.view(entity[column.key], entity) : entity[column.key]}</td>
															}
														}
													}
												})
											}
										</tr>
									})
								}
							</tbody>
						</table>
					}
					{ (!this.props.settings.entities || !this.props.settings.entities.length) && <div className="empty">There is no content in this list</div> }
				</div>
			</>
		);
	}

}


export default Table;

