import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import ProvidersForm from './form.jsx';

class ProviderNew extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			breadcrumb: { title: 'View providers', path: '/providers/' },
			fields: {
				name: { label: 'Name', type: 'text', required: true }
            },
            provider: {
				name: null
			}
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	componentDidMount() {
	}

	saveEntity(fields) {        
        API.create('providers', fields)
            .then(() => {
                toast.success('Provider has been created')
                this.props.history.push('/providers/')
            })
            .catch(err => {
                console.log(err)
                toast.error('An unexpected error has occured, please try again')
			    // this.setState({ loading: false })
            })
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'New Company'} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs} />
				<Content>
					<ProvidersForm 
						fields={this.state.fields} 
						entity={this.state.provider} 
						cancel={() => this.props.history.push('/providers')} 
						save={this.saveEntity} 
					/>
				</Content>
			</>
	}
}

export default ProviderNew;