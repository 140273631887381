import React from 'react';
import Loader from '../../layout/loader.jsx'
import API from '../../../services/api.jsx'
import ApplicationOtherPlansForm from './form.jsx';
import { toast } from 'react-toastify'

class ApplicationOtherPlansEdit extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			breadcrumb: { title: 'Back to Applications', path: '/applications' },
			insuranceInterest: {life: false, home: false, travel: false} 
		}
	}

	componentDidMount() {
		this.setState({loading: true})
		const applicationId = this.props.match.params.applicationId

        API.read('applications', applicationId).then(res => {
			console.log(res)
			const insuranceInterestAnswers = res.insuranceInterest ? res.insuranceInterest : {life: false, home: false, travel: false, critical: false} 
			this.setState({
				...this.state,
				insuranceInterest: insuranceInterestAnswers   
				
			}, () => {
				this.setState({
					...this.state,
					loading: false
				})
			})
		}).catch(err => {
			toast.error("There was a problem fetching the questions");
			console.log(err)
		})
	}

	save = (fields) => {
		const applicationId = this.props.match.params.applicationId
		let insuranceInterest = {
			insuranceInterest: {
				life: fields.life,
				travel: fields.travel,
				home: fields.home,
				critical: fields.critical
			}
		}

		API.update('applications', applicationId, insuranceInterest)
			.then(res => {
				console.log(res)
                toast.success("The application has been updated");

			})
			.catch(err => {
				console.log(err)
                toast.error("There was a problem updating the application");
			})
	}

	render() {

		if(this.state.loading)
			return <Loader />
		else
			return (
                <ApplicationOtherPlansForm 
                    questions={this.state.insuranceInterest}
					save={this.save}
                />
            )
	}
}

export default ApplicationOtherPlansEdit;