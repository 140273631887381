import React from 'react';
import API from '../../../../services/api.jsx'
import Loader from '../../../layout/loader.jsx'
import Table from '../table.jsx'
import Modal from '../modal.jsx'
import QuestionsForPlan from '../../../../services/helpers/questionsForPlan'

class OptionsList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			selectedQuestion: {},
			questionNumber: '',
			selectedSection: {label: '', value: ''},
			availableQuestions: [],
			tableItems: [
				{
					key: 'options',
					entities: [],
					label: 'Options'
				}
			]
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities = () => {
		API.list('questions').then(availableQuestions => {
			this.setState({loading: true})
			this.getQuestionsFromPlans(availableQuestions)
		}).catch(err => {
			console.log(err, ' error when fetching questions in general')
		})
	}

	hidePopup = () => {
		this.setState({
			...this.state,
			popupVisible: false
		})
	}

    openPopup = (key, label) => {
		this.setState({
			...this.state,
			popupVisible: true,
			selectedSection: {value: key, label: label}
		})
	}
	
	getQuestionsFromPlans(availableQuestions) {
		API.getQuestionsFromPlan(this.props.match.params.planId, 'options').then(questionsForPlan => {
			QuestionsForPlan.sortTableItems(questionsForPlan, 'options', 'Option').then(tableItems => {
				QuestionsForPlan.sortAvailableQuestions(availableQuestions).then(sortedQuestions => {
					this.setState({
						...this.state,
						tableItems: tableItems,
						availableQuestions: sortedQuestions,
						loading: false
					})
				})
			})
		}).catch(err => {
			console.log(err, ' error when fetching questions from plan')
		})
	}


	render() {
		const sharedProps = {
			section:'options',
			planId: this.props.match.params.planId
		}

		if(this.state.loading)
			return <Loader />
		else {
			return (
                <>
					<Table
						id={this.state.tableItems[0].key}
						label={this.state.tableItems[0].label}
						entities={this.state.tableItems[0].entities} 
						openPopup={this.openPopup}
						loadEntities={this.loadEntities}
						{...sharedProps}
					/> 

					<Modal 
						hidePopup={this.hidePopup}
						popupVisible={this.state.popupVisible}
						availableQuestions={this.state.availableQuestions}
						selectedSection={this.state.selectedSection}
						{...sharedProps}
					/>
                </>
			)
		}
	}
}

export default OptionsList;