import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import PlansForm from './form.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'

class PlanEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			breadcrumb: { title: 'View plans', path: '/providers/' + props.match.params.providerId + '/plans/' },
			fields: {
				date: {
					options: [
						{key: 'YYYY/MM/DD', label: 'YYYY/MM/DD'},
						{key: 'DD/MM/YYYY', label: 'DD/MM/YYYY'},
						{key: 'MM/DD/YYYY', label: 'MM/DD/YYYY'},
						{key: 'YY/MM/DD', label: 'YY/MM/DD'},
						{key: 'DD/MM/YY', label: 'DD/MM/YY'},
						{key: 'MM/DD/YY', label: 'MM/DD/YY'},
						{key: 'DD MM YYYY', label: 'DD MM YYYY'},
					],
				},
				language: {
					options: [
						{key: 'englishAndChinese', label: 'English & Chinese'},
						{key: 'english', label: 'English'},
						{key: 'chinese', label: 'Chinese'},
					]
				}

			}
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	componentDidMount() {
        API.getPlan(this.props.match.params.planId).then(plan => {
            console.log(plan)
            this.setState({
				...this.state, 
				loading: false, 
				plan
			})
        })
	}

	saveEntity(fields) {
		let formData = new FormData()
		let newPdf = false

		if(fields.tempFile !== undefined) {
			newPdf = true
			formData.append(fields.tempFile.name, fields.tempFile)
			delete fields['tempFile'];
		}

		this.setState({ loading: true })
		API.update('plans', this.props.match.params.planId, fields).then(() => {
			if(newPdf) {
				API.putsPdfTemplate(this.props.match.params.planId, formData).then(() => {
					toast.success('Plan has been updated')
					this.props.history.push('/providers/' + this.props.match.params.providerId + '/plans')
	
				}).catch(() => {
					toast.error('An unexpected error occured when updating the pdf template, please try again')
					this.setState({ loading: false })
				})
			} else {
				toast.success('Plan has been updated')
				this.props.history.push('/providers/' + this.props.match.params.providerId + '/plans')
			}
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Edit plan'} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs} />
				<Content>
					<PlansForm 
						entity={this.state.plan} 
						fields={this.state.fields} 
						cancel={() => this.props.history.push('/providers/' + this.props.match.params.providerId +'/plans/')} 
						save={this.saveEntity} 
					/>
				</Content>
			</>
	}
}

export default PlanEdit;