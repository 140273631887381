import React from 'react';
import API from '../../services/api.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import Loader from '../layout/loader.jsx'

class ProvidersList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			providers: {
				columns: [{
					label: 'Company',
					key: 'name'
				}, {
					label: 'Plans',
					key: 'plans',
					view: field => {
						return field.length
					}
				}, {
					action: 'edit',
					onClick: (entityId) => {
						this.props.history.push('/providers/' + entityId + '/plans')
					}
				}],
				entities: [],
				filters: [],
				actions: [
					{
						classList: 'blue add',
						label: 'New provider',
						realm: 'superAdmin',
						onClick: () => {
							this.props.history.push(`/providers/new`)
						}
					}
				],
				selectedItems: [],
				multiSelect: false
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
		API.listProviderPlans()
			.then(entities => {
				this.setState({...this.state, loading: false, providers: {...this.state.providers, entities} })
			})
			.catch(err => {
				console.log(err)
			})
	}

	viewPlan(id) {
		this.props.history.push('/providers/' + id );
	}

	render() {
		if(this.state.loading)
			return <Loader />
		return <>
			<Head title={'Company plans'} />
			<Content>
				<Table settings={this.state.providers} />				
			</Content>
		</>
	}
}

export default ProvidersList;