import React from 'react';
import Loader from '../../layout/loader.jsx'
import API from '../../../services/api.jsx'
import ApplicationEmergencyForm from './form.jsx';
import { toast } from 'react-toastify'

class ApplicationEmergencyEdit extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			breadcrumb: { title: 'Back to Applications', path: '/applications' },
			application: null
		}
	}

	componentDidMount() {
		this.setState({loading: true})
		const applicationId = this.props.match.params.applicationId
		API.read('applications', applicationId).then(application => {
			let obj = {
				emergencySurname: application.emergencySurname ? application.emergencySurname : null,
				emergencyFirstName: application.emergencyFirstName ? application.emergencyFirstName : null, 
				emergencyMiddle: application.emergencyMiddle ? application.emergencyMiddle : null,
				emergencyMobile: application.emergencyMobile ? application.emergencyMobile : null,
				emergencyHome: application.emergencyHome ? application.emergencyHome : null,
				emergencyWork: application.emergencyWork ? application.emergencyWork : null,
				emergencyEmail: application.emergencyEmail ? application.emergencyEmail : null,
				emergencyRelationship: application.emergencyRelationship ? application.emergencyRelationship : null,
				emergencyCountry: application.emergencyCountry ? application.emergencyCountry : null,
				emergencyHomeAddress: application.emergencyHomeAddress ? application.emergencyHomeAddress : null
			}

			this.setState({emergencyDetails: obj, loading: false})
		})
		.catch(err => {
			this.setState({ loading: false })
		})
	}

	save = (fields) => {
		this.setState({loading: true})
		const applicationId = this.props.match.params.applicationId

		API.update('applications', applicationId, fields).then(result => {
			console.log(result)
			this.setState({ loading: false, answers: result.answers})
			toast.success("The application has been updated");
		}).catch(err => {
			console.log(err)
			toast.error("There was a problem updating the application");
		})
	}

	render() {

		if(!this.state.emergencyDetails)
			return <Loader />
		else
			return <>
                <ApplicationEmergencyForm
					fields={this.state.emergencyDetails}
                  	save={this.save}
                />
        </>
	}
}

export default ApplicationEmergencyEdit;