import React from 'react'
import Loader from '../../layout/loader.jsx'
import API from '../../../services/api.jsx'
import ApplicationDependantsForm from './form.jsx';
import { toast } from 'react-toastify'
import recursiveCreateAnswersArray from '../../../services/helpers/recursiveCreateAnswersArray'

class ApplicationDependantsEdit extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			breadcrumb: { title: 'Back to Applications', path: '/applications' },
			application: null
		}
	}

	addAnswersToInsured = (insuredArrays, answers) => {
		return new Promise(async (res, rej) => {
			let updatedAnswers = []

			for(const i in insuredArrays) {
				const insuredArray = insuredArrays[i]

				await recursiveCreateAnswersArray(answers, insuredArray).then(answers => {
					updatedAnswers.push([].concat(...answers))
				})
			}

			res([].concat(...updatedAnswers))
		})
	}

	removeDuplicates = answers => {
		return new Promise((res, rej) => {
			const filteredArr = answers.reduce((acc, current) => {
				const x = acc.find(item => item.questionId === current.questionId);
				if (!x) {
					return acc.concat([current]);
				} else {
					return acc;
				}
			}, []);
	
			res(filteredArr)
		})
	}

	addAnswersToInsuredOne = (insuredOneQuestions, answers) => {
		return new Promise(async (res, rej) => {
			let keys = Object.keys(insuredOneQuestions)
			let updatedAnswers = []

			for(const i in keys) {
				const key = keys[i]
				const insuredArray = insuredOneQuestions[key]

				await recursiveCreateAnswersArray(answers, insuredArray).then(answers => {
					updatedAnswers.push([].concat(...answers))
				})
			}
			
			await this.removeDuplicates([].concat(...updatedAnswers)).then(filteredAnswers => {
				res(filteredAnswers)
			})
		})
	}

	// parse out the different insured questions 
	splitIntoInsured = (questions) => {
		let questionsObj = {}
		let questionsArray = []
		
		// create a key in questionsObj called insured1 etc.
		questions.forEach(question => {
			if(question && question.question && question.question.value.match(/insured[\d]/) !== null)
				questionsObj[question.question.value.match(/insured[\d]/)[0]] = []
			return question
		})

		// push the questions into each key in the questionsObj
		questions.map(question => {
			if(question && question.question && question.question.value.match(/insured[\d]/) !== null)
				questionsObj[question.question.value.match(/insured[\d]/)[0]].push(question.question)
			return question
		})

		Object.keys(questionsObj).map(key => {
			questionsArray.push(questionsObj[key])
			return key
		})

		return questionsArray
	}

	// put answers from each insured person into one array
	parseAnswers = arrayOfAnswers => {
		let newAnswers = []
		for(const i in arrayOfAnswers) {
			const answers = arrayOfAnswers[i].answers

			newAnswers.push(answers)
		}

		return [].concat(...newAnswers)
	}

	parseNameAnswers = arrayOfAnswers => {
		let names = []

		for(const i in arrayOfAnswers) {
			let obj = {}
			obj.firstName = arrayOfAnswers[i].firstName
			obj.lastName = arrayOfAnswers[i].lastName

			names.push(obj)
		}

		return [].concat(...names)
	}

	componentDidMount() {
		this.setState({loading: true})
		const applicationId = this.props.match.params.applicationId

		API.getQuestionsAndApplication(applicationId, 'dependants').then(result => {
			let nameAnswers
			if(result.insuredPersons.length === 0) {
				nameAnswers = [{firstName: null, lastName: null}]
			} else {
				nameAnswers = this.parseNameAnswers(result.insuredPersons)
			}
			let answers = this.parseAnswers(result.insuredPersons)
			let insuredQuestions = this.splitIntoInsured(result.questions)
			this.addAnswersToInsured(insuredQuestions, answers).then(updatedAnswers => {	
				answers = [].concat(...updatedAnswers)
				this.removeDuplicates(answers).then(filteredAnswers => {
					this.setState({questions: insuredQuestions, answers: filteredAnswers, nameAnswers, maxNoOfDependants: result.maxNoOfDependants, isProspect: result.isProspect, noOfDependants: result.insuredPersons.length ? result.insuredPersons.length - 1 : 0, isInsuredOneDependantOne: result.isInsuredOneDependantOne})
				})
			})
		}).catch(err => {
			toast.error("There was a problem fetching the questions");
			console.log(err)
		})
	}

	// split into insured1, insured2, insured3 etc. for answers
	// make into a nested array [[insured1], [insured2], [insured3]]
	async splitAnswersIntoInsured(answers, questionsArray) {
		let questionsObj = {}
		let answersArray = []

		// create empty array as key
		for(const i in questionsArray) {
			const question = questionsArray[i]
			for(const j in answers) {
				const answer = answers[j]
				if(question !== null) {
					if(answer.questionId === question.id) {
						questionsObj[question.value.match(/insured[\d]/)[0]] = []
					}
				}
			}
		}

		// add answers to key
		for(const i in questionsArray) {
			const question = questionsArray[i]

			for(const j in answers) {
				const answer = answers[j]
				if(question !== null) {
					if(answer.questionId === question.id) {
						questionsObj[question.value.match(/insured[\d]/)[0]].push(answer)
					}
				}
			}
		}

		// remove key values from object and put into nested arrays
		Object.keys(questionsObj).map(key => {
			answersArray.push(questionsObj[key])
			return key
		})

		return answersArray
	}

	// put all questions and follow up questions into one array of objects
	getAllQuestions = (questionsArrays) => {
		return new Promise(async(res, rej) => {
			const questionsArraysFlattened = [].concat(...questionsArrays)
			const insuredPersonsArray = []

			for(const i in questionsArraysFlattened) {
				const question = questionsArraysFlattened[i];
				let hasOptions =  question.answerType === 'radio' || question.answerType === 'select' || question.answerType === 'nationality' || question.answerType === 'checkbox';

				if(question) {
					if(question.answerType === 'address') {
						await this.getAllQuestions(question.addressQuestions).then(questions => {
							insuredPersonsArray.push(questions)
						})
					} else if(question.answerType === 'groupedQuestions' || question.answerType === 'checkboxGroup') {
						await this.getAllQuestions(question.groupQuestions).then(questions => {
							insuredPersonsArray.push(questions)
						})
					} else {
						insuredPersonsArray.push(question)
					}
				} 
	
				if (hasOptions) {
					let options = question.options
	
					for (const j in options) {
						const option = options[j];
						const noFollowUps = (option.followUps === null || option.followUps === undefined) || option.followUps.questions === null || option.followUps.questions === [] || option.followUps.questions[0] === null ||
						option.followUps.questions.length === 0
	
						if (noFollowUps) {
						} else {
							await this.getAllQuestions(option.followUps.questions).then(question => {
								insuredPersonsArray.push(question)
							})
						}
					}
				}
			}

			res([].concat(...insuredPersonsArray))
		})
	}
	
	// update general answers
	updateDependantsAnswers = (fields) => {
		return new Promise(async (res, rej) => {
			const applicationId = this.props.match.params.applicationId
			let questionsArrays = JSON.parse(JSON.stringify(fields.questions))
			const answersCopy = JSON.parse(JSON.stringify(fields.answers))
			
			// parse insuredOne questions from questionsArrays
			const keys = Object.keys(questionsArrays[0])
			const newInsuredOne = []
	
			keys.map(key => {
				return newInsuredOne.push(questionsArrays[0][key])
			})
			
			questionsArrays[0] = [].concat(...newInsuredOne)

			// // put all questions into one array of object
			await this.getAllQuestions(questionsArrays).then(async (questionsArrayNonFlattened) => {
				// flatten questions
				const flattenedQuestions = [].concat(...questionsArrayNonFlattened)
				// create an array of arrays with insuredPersons answers at correct indexes
				await this.splitAnswersIntoInsured(answersCopy, flattenedQuestions).then(async (answersSplit) => {
					// make array length the same as the number of dependants 
					answersSplit.length = fields.noOfDependants + 1
					
					let obj = {
						answers: answersSplit,
						isProspect: fields.isProspect === 'true' || fields.isProspect === true
					}
					
					await API.putAnswers(applicationId, obj, 'dependants').then(result => {
						res(result)
					}).catch(err => {
						rej(err)
					})
				})
			})
		})
	}

	save = (fields) => {
		this.setState({loading: true})
		this.updateDependantsAnswers(fields).then(updatedApplication => {
			const applicationId = this.props.match.params.applicationId
			const nameAnswers = fields.nameAnswers

			// update name answers
			for(const i in updatedApplication.insuredPersons) {
				const insuredPerson = updatedApplication.insuredPersons[i]
				insuredPerson.firstName = nameAnswers[i].firstName
				insuredPerson.lastName = nameAnswers[i].lastName
			}

			API.update('applications', applicationId, updatedApplication).then(result => {
				this.setState({loading: false, application: result})
				toast.success("The application has been updated");
			}).catch(err => {
				console.log(err)
				toast.error("There was a problem updating the application");
			})
		}).catch(err => {
			console.log(err)
			toast.error("There was a problem updating the application");
		})
	}

	render() {
		if(!this.state.questions && !this.state.answers)
			return <Loader />
		else
			return <>
                <ApplicationDependantsForm
					answers={this.state.answers}
					nameAnswers={this.state.nameAnswers}
					questions={this.state.questions} 
					isProspect={this.state.isProspect}
					noOfDependants={this.state.noOfDependants}
					maxNoOfDependants={this.state.maxNoOfDependants}
					isInsuredOneDependantOne={this.state.isInsuredOneDependantOne}
					getAllQuestions={(questions) => this.getAllQuestions(questions)}
					save={this.save}
                />
        </>
	}
}

export default ApplicationDependantsEdit;