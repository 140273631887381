import React, {useState, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom'
import exportPdf from '../../services/helpers/exportPdf'
import API from '../../services/api'
import User from '../../services/user'
import { toast } from 'react-toastify'
// import axios from 'axios';

const Form = (props) => {
    const history = useHistory()
    const params = useParams()
	let [operators, setOperators] = useState([])
    let [operatorId, setOperatorId] = useState('')

    useEffect(() => {
        API.read('applications', params.applicationId).then(application => {
            setOperatorId(application.operator)
            API.list('applications/get-operators').then(operators => {
                setOperators(operators)
            })
            .catch(err => (
                console.log(err)
            ))
        }).catch(err => {
            console.log(err)
        })
    }, [params.applicationId])


	const onCancel = (e) => {
		e.preventDefault()
        history.push('/applications')
	}

    const updateOperator = (e) => {
        setOperatorId(e.target.value)
        let obj = {
            [e.target.name]: e.target.value
        }

        API.update('applications/update-applications-operators', params.applicationId, obj).then(res => {
            console.log(res)
            toast.success("Operator successfully updated!");
        })
        .catch(err => {
            console.log(err)
            toast.error("There was a problem updating the operator");
        })
    }

    const generateQrCode = applicationId => {
        API.getQrCode(applicationId).then(res => {
            const a = document.createElement("a")
            a.href = `data:image/png;base64,${Buffer.from(res.data, 'binary').toString('base64')}`;
            a.download = "qrcode.png"
            a.click();
        }).catch(err => {
            toast.error("There was a problem creating the QR code");
        })}

    return (
        <div className="actions clearfix">
            <div className="buttons clearfix left">
                {
                    User.hasRole('admin') && 
                    <div className="select operator">
                        <select name="operator" onChange={(e) => updateOperator(e)} value={operatorId} defaultValue="placeholder">
                            <option value="placeholder">Please choose an operator....</option>
                            {
                                operators && operators.map(operator => (
                                    <option value={operator.id}>{operator.name}</option>
                                ))
                            }
                        </select>
                    </div>
                }
                <div className="button blue" onClick={() => exportPdf(params.applicationId)}>Export PDF</div>
                <div className="button blue" onClick={() => generateQrCode(params.applicationId)}>Generate QR Code</div>
            </div>
            <div className="buttons clearfix">
                <div className="button grey" onClick={(e) => onCancel(e)}>Cancel</div>
                <button className="blue">Save</button>
            </div>

        </div>
    )
}



export default Form;